define("@trovedata/trove-scenario-planner/scenario-planner/plan/edit/controller", ["exports", "ember-concurrency-decorators", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/runloop", "@ember/service", "@ember/utils", "@glimmer/tracking", "@trovedata/sunstone-ui-commons/utils/array-utils", "@trovedata/sunstone-ui-commons/utils/string-utils", "@trovedata/trove-scenario-planner/utils/agg-utils", "@trovedata/trove-scenario-planner/utils/map-utils"], function (_exports, _emberConcurrencyDecorators, _controller, _object, _computed, _runloop, _service, _utils, _tracking, _arrayUtils, _stringUtils, _aggUtils, _mapUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let EditController = _exports.default = (_dec = (0, _controller.inject)('scenario-planner'), _dec2 = (0, _controller.inject)('scenario-planner/plan'), _dec3 = (0, _computed.alias)('planController.aggType'), _dec4 = (0, _computed.alias)('planController.selectedPlanSummary'), _dec5 = (0, _computed.alias)('planController.filters'), _dec6 = (0, _computed.alias)('planController.parsedFilters'), _dec7 = (0, _computed.alias)('currentPlanSummary.projectedCost'), _dec8 = (0, _computed.alias)('planController.selectedPlan.region'), _dec9 = (0, _computed.alias)('planController.selectedPlan'), _dec10 = (0, _computed.alias)('planController.show'), _dec11 = (0, _computed.alias)('planController.grouping'), _dec12 = (0, _computed.alias)('planController.year'), _dec13 = (0, _computed.alias)('planController.geoDataVersion'), _dec14 = (0, _computed.alias)('planController.mapAggGeomGroupToFeature'), _dec15 = (0, _computed.alias)('planController.mapGroupingToFeature'), _dec16 = (0, _computed.alias)('planController.mapPolygonsToFeatures'), _dec17 = (0, _computed.reads)('planController.getGroupingStatistics'), _dec18 = (0, _computed.reads)('planController.getPlanGroupingSummary'), _dec19 = (0, _computed.reads)('planController.groupingStatistics'), _dec20 = (0, _computed.reads)('planController.planGroupingSummary'), _dec21 = (0, _object.computed)('selectedAggs.[]'), _dec22 = (0, _object.computed)('selectedAggs.[]'), _dec23 = (0, _object.computed)('parsedFilters.[]'), _class = class EditController extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "aggGeom", _descriptor, this);
      _initializerDefineProperty(this, "customization", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "featureFlag", _descriptor4, this);
      _initializerDefineProperty(this, "troveFetch", _descriptor5, this);
      _defineProperty(this, "queryParams", ['priorParams']);
      _defineProperty(this, "currentAggregations", void 0);
      _defineProperty(this, "priorParams", void 0);
      _defineProperty(this, "selectedAggs", []);
      _defineProperty(this, "mapContext", void 0);
      _initializerDefineProperty(this, "featureCollection", _descriptor6, this);
      _initializerDefineProperty(this, "showMapWarning", _descriptor7, this);
      _initializerDefineProperty(this, "useClick", _descriptor8, this);
      _initializerDefineProperty(this, "applicationController", _descriptor9, this);
      _initializerDefineProperty(this, "planController", _descriptor10, this);
      _initializerDefineProperty(this, "aggType", _descriptor11, this);
      _initializerDefineProperty(this, "currentPlanSummary", _descriptor12, this);
      _initializerDefineProperty(this, "filters", _descriptor13, this);
      _initializerDefineProperty(this, "parsedFilters", _descriptor14, this);
      _initializerDefineProperty(this, "projectedCost", _descriptor15, this);
      _initializerDefineProperty(this, "region", _descriptor16, this);
      _initializerDefineProperty(this, "selectedPlan", _descriptor17, this);
      _initializerDefineProperty(this, "show", _descriptor18, this);
      _initializerDefineProperty(this, "grouping", _descriptor19, this);
      _initializerDefineProperty(this, "year", _descriptor20, this);
      _initializerDefineProperty(this, "geoDataVersion", _descriptor21, this);
      _initializerDefineProperty(this, "mapAggGeomGroupToFeature", _descriptor22, this);
      _initializerDefineProperty(this, "mapGroupingToFeature", _descriptor23, this);
      _initializerDefineProperty(this, "mapPolygonsToFeatures", _descriptor24, this);
      _initializerDefineProperty(this, "getGroupingStatistics", _descriptor25, this);
      _initializerDefineProperty(this, "getPlanGroupingSummary", _descriptor26, this);
      _initializerDefineProperty(this, "groupingStatistics", _descriptor27, this);
      _initializerDefineProperty(this, "planGroupingSummary", _descriptor28, this);
    }
    get plannedRemovalMaps() {
      if ((0, _utils.isPresent)(this.selectedAggs)) {
        const selected = this.selectedAggs?.reduce((hash, {
          content
        }) => {
          const {
            guidelineDistribution
          } = content;
          // tslint:disable-next-line: forin
          for (const bucket in guidelineDistribution) {
            if (bucket in guidelineDistribution) {
              if (!hash[bucket]) {
                hash[bucket] = 0;
              }
              hash[bucket] += guidelineDistribution[bucket];
            }
          }
          return hash;
        }, {}) || {};
        return [{
          grouping: 'guideline',
          selected
        }];
      } else {
        return [];
      }
    }
    get selectedStats() {
      const stats = {
        capital: 0,
        lineCount: 0,
        maintenance: 0,
        removals: 0,
        total: 0
      };
      this.selectedAggs?.forEach(({
        content
      }) => {
        const {
          guidelinesTotalCapital,
          guidelinesTotalMaintenance
        } = content;
        stats.capital += guidelinesTotalCapital || 0;
        stats.maintenance += guidelinesTotalMaintenance || 0;
        stats.total += (guidelinesTotalCapital || 0) + (guidelinesTotalMaintenance || 0);
        stats.removals += content.guidelineTrees || 0;
        stats.lineCount += 1;
      });
      return stats;
    }
    get filtersByField() {
      return (0, _arrayUtils.buildHash)(this.parsedFilters, 'field');
    }
    useEventValidation(feature) {
      const agg = feature.getProperty('agg');
      // @ts-ignore
      return agg ? agg.constructor.modelName !== 'polygon-agg' : true;
    }

    /**
     * This is the geom rendering handler for all four agg geom groups. `wipeMap`
     * determines if the map should be reset first or geoms should be appended to
     * an existing collections.
     */
    aggGeomHandler(aggGeomGroups, wipeMap, includeInBounds = true, highlightAggName) {
      const {
        parsedFilters,
        grouping
      } = this;
      // Convert the supplied aggGeomGroups into a set of features.
      const features = aggGeomGroups.filter(aggGeomGroup => aggGeomGroup.geom).map(aggGeomGroup => {
        const feature = this.mapAggGeomGroupToFeature(aggGeomGroup);
        feature.properties.bounds = highlightAggName && feature.properties?.agg?.name === highlightAggName ? true : includeInBounds;
        feature.properties.strokeWeight = highlightAggName && feature.properties?.agg?.name === highlightAggName ? _mapUtils.ACTIVE_STROKE_WEIGHT : feature.properties.strokeWeight;
        return feature;
      });

      /**
       * If the grouping exists and there are currently no filters applied, render
       * the shaded grouping polygon on the map.
       */
      if (grouping && !parsedFilters.length) {
        features.push(this.mapGroupingToFeature(grouping));
      }
      wipeMap ? this.featureCollection = {
        features,
        type: 'FeatureCollection'
      } : this.featureCollection = {
        features: this.featureCollection?.features?.concat(features),
        type: 'FeatureCollection'
      };
    }

    /**
     * This function will be called only during polygon view level. After polygons
     * have been rendered on the map, it will retrieve all the line's cached (or
     * promise) span geoms and render them on the map. We turn off Google Map's
     * `fitBounds` function so that the map does not zoom out to fit all the spans
     * as we want it to focus on the area with the polygons.
     */
    *loadLineSpanGeoms({
      lineName,
      name
    }) {
      const groups = this.aggGeom.cachedLineSpanGeomGroups[lineName];
      if (groups?.length) this.aggGeomHandler(groups, false, false, name);
    }
    *loadPolygonsForSpan(spanName) {
      const promise = this.store.query('polygon-agg', {
        endpoint: 'findAllCollectionBySpecification',
        params: {
          region: this.grouping.displayName,
          spanName,
          year: this.geoDataVersion
        }
      });
      return yield this.troveFetch.resolve(promise);
    }

    /**
     * This function will be called during line, segment, and span view level (not
     * polygon). It retrives applicable geoms for the aggs and passes them to the
     * handler. If no geoms are detected, then the map resets.
     */
    *loadFilteredGeometries(aggs) {
      const {
        aggGeom,
        aggType,
        geoDataVersion
      } = this;
      this.showMapWarning = false;

      // @ts-ignore
      const groups = yield aggGeom.getGeomsForSelectedAgg.perform(geoDataVersion, this.grouping.displayName, aggType, aggs);
      if (groups?.length) {
        this.aggGeomHandler(groups, true);
      } else {
        this.resetMap();
      }
    }
    mutFilters(filters) {
      this.set('filters', filters?.length ? JSON.stringify(filters) : undefined);
    }
    selectFeature(feature) {
      const agg = feature.getProperty('agg');
      if (agg) {
        const {
          aggType,
          filters
        } = (0, _aggUtils.selectAgg)(agg);
        this.aggType = aggType;
        this.mutFilters(filters);
      }
    }
    resetMap() {
      this.featureCollection = {
        features: [],
        type: 'FeatureCollection'
      };
    }

    /**
     * This function will be called only during polygon view level. It renders the
     * polygons on the map. If polygon geoms are availble, it will reset the map
     * with the polygons. Otherwise, it will proceed to render a single span
     * (parent level). Lastly, it will call the fuction to load the line's span
     * geoms.
     *
     * This will be called even if the map is not yet rendered. Thus, we cache the
     * polygons so we can apply them at a later time.
     */
    async onSpanLoad(span) {
      this.showMapWarning = false;

      /*
      Map the retrieved polygons into a set of features which can be pushed onto the map.
      */
      // @ts-ignore
      const polygons = await this.loadPolygonsForSpan.perform(span.spanName);
      const polygonGeometries = this.mapPolygonsToFeatures(polygons);
      this.featureCollection = {
        features: polygonGeometries,
        type: 'FeatureCollection'
      };

      /*
      Fetch, map and render all of the span geometries belonging to the current line.
      */
      // @ts-ignore
      (0, _runloop.next)(this, () => this.loadLineSpanGeoms.perform(span));
    }
    setUpBreadCrumbs() {
      const planName = this.selectedPlan.name;
      const label = `Plan (${(0, _stringUtils.ellipse)(planName, 20)})`;
      const bcs = [...this.applicationController.breadCrumbs];
      // @ts-ignore
      const filteredBcs = bcs.rejectBy('isPlanLabel');
      filteredBcs.push({
        isPlanLabel: true,
        label
      });
      this.applicationController.breadCrumbs = filteredBcs;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "aggGeom", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "customization", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "featureFlag", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "troveFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "featureCollection", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        features: [],
        type: 'FeatureCollection'
      };
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showMapWarning", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "useClick", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "applicationController", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "planController", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "aggType", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "currentPlanSummary", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "parsedFilters", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "projectedCost", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "region", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "selectedPlan", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "show", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "grouping", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "year", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "geoDataVersion", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "mapAggGeomGroupToFeature", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "mapGroupingToFeature", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "mapPolygonsToFeatures", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "getGroupingStatistics", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "getPlanGroupingSummary", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "groupingStatistics", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "planGroupingSummary", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "plannedRemovalMaps", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "plannedRemovalMaps"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedStats", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "selectedStats"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filtersByField", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "filtersByField"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadLineSpanGeoms", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "loadLineSpanGeoms"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadPolygonsForSpan", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "loadPolygonsForSpan"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadFilteredGeometries", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "loadFilteredGeometries"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mutFilters", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "mutFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectFeature", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectFeature"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetMap", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "resetMap"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSpanLoad", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSpanLoad"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setUpBreadCrumbs", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setUpBreadCrumbs"), _class.prototype), _class); // DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
});