define("@trovedata/sunstone-ui-commons/components/async-button/component", ["exports", "@ember/component", "@ember/runloop", "@ember/object", "@trovedata/sunstone-ui-commons/components/async-button/template", "@ember-decorators/object", "@ember/object/computed", "@ember-decorators/component"], function (_exports, _component, _runloop, _object, _template, _object2, _computed, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AsyncButtonComponent = _exports.default = (_dec = (0, _component2.layout)(_template.default), _dec2 = (0, _component2.tagName)('button'), _dec3 = (0, _component2.classNames)('async-button'), _dec4 = (0, _component2.attribute)('disabled'), _dec5 = (0, _computed.or)('isPending', 'disabled'), _dec6 = (0, _object.computed)('defaultText', 'pendingText', 'isPending'), _dec7 = (0, _object.computed)('defaultIcon', 'pendingIcon', 'isPending'), _dec8 = (0, _object2.observes)('promise'), _dec(_class = _dec2(_class = _dec3(_class = (_class2 = class AsyncButtonComponent extends _component.default.reopenClass({
    positionalParams: ['paramAction']
  }) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "type", _descriptor, this);
      _defineProperty(this, "defaultText", 'Save');
      _defineProperty(this, "pendingText", 'Saving...');
      _defineProperty(this, "defaultIcon", null);
      _defineProperty(this, "pendingIcon", 'interface-loading');
      _defineProperty(this, "showSpinner", true);
      _defineProperty(this, "isPending", false);
      _defineProperty(this, "disabled", false);
      _defineProperty(this, "promise", void 0);
      _defineProperty(this, "_action", void 0);
      _defineProperty(this, "paramAction", void 0);
      _defineProperty(this, "action", void 0);
      _defineProperty(this, "onButtonClick", void 0);
      _initializerDefineProperty(this, "isDisabled", _descriptor2, this);
    }
    get text() {
      let defaultText = this.get('defaultText');
      let pendingText = this.get('pendingText');
      let isPending = this.get('isPending');
      return isPending ? pendingText : defaultText;
    }
    get icon() {
      let defaultIcon = this.get('defaultIcon');
      let pendingIcon = this.get('pendingIcon');
      let isPending = this.get('isPending');
      return isPending ? pendingIcon : defaultIcon;
    }
    didReceiveAttrs() {
      super.didReceiveAttrs();
      (0, _object.set)(this, '_action', (0, _object.get)(this, 'paramAction') || (0, _object.get)(this, 'action') || (0, _object.get)(this, 'onButtonClick'));
    }
    click(event) {
      event.stopPropagation();
      let action = (0, _object.get)(this, '_action');
      if (typeof action === 'function') {
        (0, _object.set)(this, 'promise', action());
      }
    }
    handlePromise() {
      let promise = (0, _object.get)(this, 'promise');
      if (!promise || typeof promise.finally !== 'function') {
        return;
      }
      (0, _runloop.run)(() => {
        (0, _object.set)(this, 'isPending', true);
      });
      promise.finally(() => {
        (0, _runloop.run)(() => {
          (0, _object.set)(this, 'isPending', false);
        });
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "type", [_component2.attribute], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'button';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "isDisabled", [_dec4, _dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "text", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "text"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "icon", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "icon"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handlePromise", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "handlePromise"), _class2.prototype), _class2)) || _class) || _class) || _class);
});