define("@trovedata/sunstone-ui-commons/models/data-field", ["exports", "@ember/object/computed", "@ember/object", "ember-data", "ember-data/model"], function (_exports, _computed, _object, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const {
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;
  const hasOne = belongsTo;
  let DataField = _exports.default = (_dec = attr('string'), _dec2 = attr('string', {
    defaultValue: ''
  }), _dec3 = attr('string', {
    defaultValue: 'default'
  }), _dec4 = attr('string'), _dec5 = attr('string'), _dec6 = attr('boolean'), _dec7 = attr('boolean'), _dec8 = attr('boolean'), _dec9 = attr('number'), _dec10 = attr('boolean'), _dec11 = attr('string'), _dec12 = attr('number'), _dec13 = attr('string'), _dec14 = attr('number'), _dec15 = attr('string'), _dec16 = hasMany('category', {
    async: true
  }), _dec17 = hasOne('analytic-instance', {
    async: true
  }), _dec18 = belongsTo('datasource', {
    async: true,
    inverse: null
  }), _dec19 = (0, _computed.alias)('defaultField'), _dec20 = (0, _computed.or)('displayName', 'name'), _dec21 = (0, _computed.alias)('defaultThirdPartyField'), _dec22 = (0, _object.computed)('categories.lastObject'), _dec23 = (0, _object.computed)('ontologyReference'), _dec24 = (0, _object.computed)('ontologyReference'), _dec25 = (0, _object.computed)('isAnalytic', 'isTrove'), _class = class DataField extends _model.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "name", _descriptor, this);
      _initializerDefineProperty(this, "description", _descriptor2, this);
      _initializerDefineProperty(this, "ontologyReference", _descriptor3, this);
      _initializerDefineProperty(this, "dataType", _descriptor4, this);
      _initializerDefineProperty(this, "displayName", _descriptor5, this);
      _initializerDefineProperty(this, "visible", _descriptor6, this);
      _initializerDefineProperty(this, "defaultField", _descriptor7, this);
      _initializerDefineProperty(this, "defaultThirdPartyField", _descriptor8, this);
      _initializerDefineProperty(this, "orgId", _descriptor9, this);
      _initializerDefineProperty(this, "active", _descriptor10, this);
      _initializerDefineProperty(this, "rawColumn", _descriptor11, this);
      _initializerDefineProperty(this, "displayOrder", _descriptor12, this);
      _initializerDefineProperty(this, "aggregrate", _descriptor13, this);
      // TEMP FIELDS
      _initializerDefineProperty(this, "dataTypeId", _descriptor14, this);
      _initializerDefineProperty(this, "dataSource", _descriptor15, this);
      // END
      _initializerDefineProperty(this, "categories", _descriptor16, this);
      _initializerDefineProperty(this, "analyticInstance", _descriptor17, this);
      _initializerDefineProperty(this, "datasource", _descriptor18, this);
      _initializerDefineProperty(this, "default", _descriptor19, this);
      _initializerDefineProperty(this, "label", _descriptor20, this);
      _initializerDefineProperty(this, "thirdParty", _descriptor21, this);
      _defineProperty(this, "validations", {
        name: {
          presence: true
        },
        ontologyReference: {
          presence: true
        },
        dataType: {
          presence: true
        },
        displayName: {
          presence: true
        },
        visible: {
          presence: true
        },
        categories: true,
        dataSource: true
      });
    }
    get category() {
      return this.categories.get('lastObject');
    }
    set category(value) {
      //@ts-ignore
      return this.get('categories').then(categories => {
        categories.clear();
        if (value !== null) {
          categories.pushObject(value);
        }
        return value;
      });
    }
    get isAnalytic() {
      return this.get('ontologyReference') != null && this.get('ontologyReference').toLowerCase() === 'analytic';
    }
    get isTrove() {
      return this.get('ontologyReference') != null && this.get('ontologyReference').toLowerCase() === 'trove';
    }
    get isDefault() {
      return !this.get('isAnalytic') && !this.get('isTrove');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "ontologyReference", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dataType", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "displayName", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "visible", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "defaultField", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "defaultThirdPartyField", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "orgId", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "active", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "rawColumn", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "displayOrder", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "aggregrate", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "dataTypeId", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "dataSource", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "categories", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "analyticInstance", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "datasource", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "default", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "label", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "thirdParty", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "category", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "category"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isAnalytic", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "isAnalytic"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isTrove", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "isTrove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isDefault", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "isDefault"), _class.prototype), _class);
});