define("@trovedata/ember-keycloak-auth/services/keycloak-session", ["exports", "@ember/service", "keycloak-js", "rsvp", "@ember/object"], function (_exports, _service, _keycloakJs, _rsvp, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  /*eslint no-undef: "error"*/
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const {
    Promise
  } = _rsvp.default;

  /**
   * Injectable Ember service that wraps an application wide Keycloak js instance.
   *
   * @class KeycloakSessionService
   * @public
   */
  let KeycloakSessionService = _exports.default = (_dec = (0, _object.computed)('_keycloak', 'timestamp'), _class = class KeycloakSessionService extends _service.default {
    constructor(...args) {
      super(...args);
      /**
       * The injected Ember router service.
       *
       * @property router
       * @type {RouterService}
       */
      _initializerDefineProperty(this, "router", _descriptor, this);
      _defineProperty(this, "_keycloak", void 0);
      _defineProperty(this, "profile", void 0);
      /**
       * Value in seconds used in calls to KeyCloak.updateToken(minValidity). Default 30.
       *
       * @property minValidity
       * @type {number}
       */
      _defineProperty(this, "minValidity", 30);
      /**
       * Bound property to track session state. Indicates that a keycloak session has been successfully created. Default false.
       *
       * @property ready
       * @type {boolean}
       */
      _defineProperty(this, "ready", false);
      /**
       * Bound property to track session state. Indicates that the session has authenticated. Default false.
       *
       * @property authenticated
       * @type {boolean}
       */
      _defineProperty(this, "authenticated", false);
      /**
       * Bound property to track session state. Track last activity time.
       *
       * @property timestamp
       * @type {Date}
       */
      _defineProperty(this, "timestamp", void 0);
      /**
       * Keycloak.init() option. Should be one of 'check-sso' or 'login-required'. Default 'login-required'.
       * See http://www.keycloak.org/documentation.html for complete details.
       *
       * @property onLoad
       * @type {String}
       */
      _defineProperty(this, "onLoad", 'login-required');
      /**
       * Keycloak.init() option. Should be one of 'query' or 'fragment'. Default 'fragment'.
       * See http://www.keycloak.org/documentation.html for complete details.
       *
       * @property responseMode
       * @type {String}
       */
      _defineProperty(this, "responseMode", 'fragment');
      /**
       * Keycloak.init() option. Should be one of 'standard', 'implicit' or 'hybrid'. Default 'standard'.
       * See http://www.keycloak.org/documentation.html for complete details.
       *
       * @property flow
       * @type {String}
       */
      _defineProperty(this, "flow", 'standard');
      /**
       * Keycloak.init() option. Default 'false'.
       *
       * @property checkLoginIframe
       * @type {boolean}
       */
      _defineProperty(this, "checkLoginIframe", false);
      /**
       * Keycloak.init() option. Default '5'.
       *
       * @property checkLoginIframeInterval
       * @type {number}
       */
      _defineProperty(this, "checkLoginIframeInterval", 5);
      /**
       * Keycloak.login() option.
       *
       * @property idpHint
       * @type {String}
       */
      _defineProperty(this, "idpHint", void 0);
      /**
       * Keycloak.login() option.
       *
       * @property idpHint
       * @type {String}
       */
      _defineProperty(this, "verbose", false);
      /**
       * Keycloak callback function.
       *
       * @property onReady
       * @type {Function}
       */
      _defineProperty(this, "onReady", authenticated => {
        (0, _object.set)(this, 'ready', true);
        (0, _object.set)(this, 'authenticated', authenticated);
        (0, _object.set)(this, 'timestamp', new Date());
        console.info(`KeycloakSessionService :: onReady -> ${authenticated}`);
      });
      /**
       * Keycloak callback function.
       *
       * @property onAuthSuccess
       * @type {Function}
       */
      _defineProperty(this, "onAuthSuccess", () => {
        (0, _object.set)(this, 'authenticated', true);
        (0, _object.set)(this, 'timestamp', new Date());
        if (this.verbose) {
          console.debug(`KeycloakSessionService :: onAuthSuccess :: token -> ${this.token}`);
        }
      });
      /**
       * Keycloak callback function.
       *
       * @property onAuthError
       * @type {Function}
       */
      _defineProperty(this, "onAuthError", errorData => {
        (0, _object.set)(this, 'authenticated', false);
        (0, _object.set)(this, 'timestamp', new Date());
        console.warn(`KeycloakSessionService :: onAuthError :: error -> ${errorData.error}, description -> ${errorData.error_description}`);
      });
      /**
       * Keycloak callback function.
       *
       * @property onAuthRefreshSuccess
       * @type {Function}
       */
      _defineProperty(this, "onAuthRefreshSuccess", () => {
        (0, _object.set)(this, 'authenticated', true);
        (0, _object.set)(this, 'timestamp', new Date());
        if (this.verbose) {
          console.debug(`KeycloakSessionService :: onAuthRefreshSuccess :: token -> ${this.token}`);
        }
      });
      /**
       * Keycloak callback function.
       *
       * @property onAuthRefreshError
       * @type {Function}
       */
      _defineProperty(this, "onAuthRefreshError", () => {
        (0, _object.set)(this, 'authenticated', false);
        (0, _object.set)(this, 'timestamp', new Date());
        this.clearToken();
        console.warn('KeycloakSessionService :: onAuthRefreshError');
      });
      /**
       * Keycloak callback function.
       *
       * @property onTokenExpired
       * @type {Function}
       */
      _defineProperty(this, "onTokenExpired", () => {
        this.wrappedCall();
        // set(this, 'authenticated', false);
        // set(this, 'timestamp', new Date());
        // console.info('KeycloakSessionService :: onTokenExpired');
      });
      /**
       * Keycloak callback function.
       *
       * @property onAuthLogout
       * @type {Function}
       */
      _defineProperty(this, "onAuthLogout", () => {
        (0, _object.set)(this, 'authenticated', false);
        (0, _object.set)(this, 'timestamp', new Date());
        console.info('KeycloakSessionService :: onAuthLogout');
      });
    }
    /**
     * @method installKeycloak
     * @param {*[]} parameters Constructor parameters for Keycloak object - see Keycloak JS adapter docs for details
     */
    installKeycloak(parameters) {
      if (this.verbose) {
        console.debug('KeycloakSessionService :: install');
      }
      let keycloak = new _keycloakJs.default(parameters);
      this._installKeycloak(keycloak);
    }
    _installKeycloak(keycloak) {
      keycloak.onReady = this.onReady;
      keycloak.onAuthSuccess = this.onAuthSuccess;
      keycloak.onAuthError = this.onAuthError;
      keycloak.onAuthRefreshSuccess = this.onAuthRefreshSuccess;
      keycloak.onAuthRefreshError = this.onAuthRefreshError;
      keycloak.onTokenExpired = this.onTokenExpired;
      keycloak.onAuthLogout = this.onAuthLogout;
      (0, _object.set)(this, '_keycloak', keycloak);
      (0, _object.set)(this, 'timestamp', new Date());
      if (this.verbose) {
        console.debug('KeycloakSessionService :: install :: completed');
      }
    }

    /**
     * @method initKeycloak
     */
    initKeycloak() {
      if (this.authenticated) return;
      if (this.verbose) console.debug('KeycloakSessionService :: init');
      let options = this.getProperties('onLoad', 'responseMode', 'checkLoginIframe', 'checkLoginIframeInterval', 'flow');
      if (this.keycloak) {
        let keycloak = this.keycloak;
        return new Promise((resolve, reject) => {
          keycloak.init(options).then(authenticated => {
            console.info('KeycloakSessionService :: init complete');
            resolve(authenticated);
          }, reason => {
            console.warn('KeycloakSessionService :: init failed');
            reject(reason);
          });
        });
      }
    }

    /**
     * The wrapped Keycloak instance.
     *
     * @property keycloak
     * @type {Keycloak}
     */
    get keycloak() {
      return this._keycloak;
    }

    /**
     * The current Keycloak subject.
     *
     * @property subject
     * @type {string | undefined}
     */
    get subject() {
      return this.keycloak ? this.keycloak.subject : undefined;
    }

    /**
     * The current Keycloak refreshToken.
     *
     * @property refreshToken
     * @type {string}
     */
    get refreshToken() {
      return this.keycloak ? this.keycloak.refreshToken : undefined;
    }

    /**
     * The current Keycloak token.
     *
     * @property token
     * @type {string}
     */
    get token() {
      return this.keycloak ? this.keycloak.token : undefined;
    }

    /**
     * The current Keycloak tokenParsed.
     *
     * @property tokenParsed
     * @type {string}
     */
    get tokenParsed() {
      return this.keycloak ? this.keycloak.tokenParsed : undefined;
    }

    /**
     * Convenience property presents the current token as the Authorization header typically required by calls to a back end service.
     * @property headers
     * @type {Object}
     */
    get headers() {
      return {
        'Authorization': `Bearer ${this.token}`
      };
    }

    /**
     * Delegates to the wrapped Keycloak instance's method.
     *
     * @method hasRealmRole
     * @param role {string} The role to check
     * @return {boolean} True if user in role.
     */
    hasRealmRole(role) {
      return !!(this.keycloak && this.keycloak.hasRealmRole(role));
    }

    /**
     * Delegates to the wrapped Keycloak instance's method.
     *
     * @method hasResourceRole
     * @param role {string} The role to check
     * @param resource {string} The resource to check
     * @return {boolean} True if user in role.
     */
    hasResourceRole(role, resource) {
      return !!(this.keycloak && this.keycloak.hasResourceRole(role, resource));
    }
    inRole(role, resource) {
      if (role && resource) {
        return this.hasResourceRole(role, resource);
      }
      if (role) {
        return this.hasRealmRole(role);
      }
      return false;
    }

    /**
     * Delegates to the wrapped Keycloak instance's method using the minValidity property.
     *
     * @method updateToken
     * @return {Promise} Wrapped promise.
     */
    updateToken() {
      return new _rsvp.default.Promise((resolve, reject) => {
        if (this.keycloak) {
          this.keycloak.updateToken(this.minValidity).then(refreshed => {
            resolve(refreshed);
          }, () => {
            console.debug('update token resolved as error');
            reject(new Error('authentication token update failed'));
          });
        } else {
          reject(new Error("No installed keycloak instance"));
        }
      });
    }

    /**
     * Delegates to the wrapped Keycloak instance's method.
     *
     * @method clearToken
     * @return {Promise} Wrapped promise.
     */
    clearToken() {
      if (this.keycloak) {
        this.keycloak.clearToken();
      }
    }

    /**
     * Parses the redirect url from the intended 'to' route of a transition.
     *
     * @method _parseRedirectUrl
     * @param {RouterService} router The ember router service.
     * @param {Transition} transition The transition in progress.
     * @return {String} URL to include as the Keycloak redirect
     * @private
     */
    _parseRedirectUrl() {
      // @ts-ignore
      console.debug(`KeycloakSessionService :: _parseRedirectUrl :: ${window.location.origin} + ${this.router.rootURL} + ${this.router.currentURL}`);
      let redirect = '/';

      // @ts-ignore
      if (this.router.rootURL) {
        // @ts-ignore
        redirect = redirect + this.router.rootURL;
      }
      if (this.router.currentURL) {
        redirect = redirect + this.router.currentURL;
      }
      redirect = window.location.origin + redirect.replace(new RegExp('//', 'g'), '/');
      console.debug(`KeycloakSessionService :: _parseRedirectUrl :: ${redirect}`);
      return redirect;
    }

    /**
     * Delegates to the wrapped Keycloak instance's method.
     *
     * @method loadUserProfile
     * @return {RSVP.Promise} Resolves on server response
     */
    loadUserProfile() {
      return new _rsvp.default.Promise((resolve, reject) => {
        if (this.keycloak) {
          this.keycloak.loadUserProfile().then(profile => {
            console.debug(`Loaded profile for ${profile.id}`);
            (0, _object.set)(this, 'profile', profile);
            resolve(profile);
          }, error => {
            reject(error);
          });
        } else {
          reject(new Error("KeycloakSessionService :: no installed keycloak instance"));
        }
      });
    }

    /**
     * Delegates to the wrapped Keycloak instance's method.
     *
     * @method login
     * @param {String} redirectUri Optional redirect url
     * @return {Promise} Resolves on server response
     */
    login(redirectUri) {
      let options = {
        redirectUri
      };

      // Add idpHint to options, if it is populated
      if (this.idpHint) {
        options.idpHint = this.get('idpHint');
      }
      return new _rsvp.default.Promise((resolve, reject) => {
        if (this.keycloak) {
          this.keycloak.login(options).then(() => {
            console.debug('KeycloakSessionService :: login :: success');
            resolve('login OK');
          }, () => {
            console.debug('KeycloakSessionService :: login error');
            reject(new Error('login failed'));
          });
        } else {
          reject(new Error("KeycloakSessionService :: no installed keycloak instance"));
        }
      });
    }

    /**
     * Delegates to the wrapped Keycloak instance's method.
     *
     * @method logout
     * @param {String} redirectUri Optional redirect url
     */
    async logout(redirectUri) {
      const options = {
        redirectUri
      };
      if (this.keycloak) {
        await this.keycloak.logout(options);
        console.debug('KeycloakSessionService :: logout :: success');
        keycloak.clearToken();
      }
    }
    wrappedCall(call) {
      return this.updateToken().then(result => {
        if (result && this.verbose) {
          console.debug(`KeycloakSessionService :: token was refreshed prior to wrapped call`);
        }
        return true;
      }).then(call, reason => {
        console.warn(`KeycloakSessionService :: update token :: rejected :: ${reason}`);
        let url = this._parseRedirectUrl();
        this.login(url);
        throw reason;
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "keycloak", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "keycloak"), _class.prototype), _class);
});