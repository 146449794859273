define("ember-paper/components/paper-menu/content/component", ["exports", "@ember/component", "ember-paper/components/paper-menu/content/template", "@ember/object", "ember-css-transitions/utils/transition-utils", "@ember-decorators/component", "ember-paper/utils/key-constants", "@ember/application", "ember-paper/utils/ebd-get-parent"], function (_exports, _component, _template, _object, _transitionUtils, _component2, _keyConstants, _application, _ebdGetParent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function waitForAnimations(element, callback) {
    let computedStyle = window.getComputedStyle(element);
    if (computedStyle.transitionDuration && computedStyle.transitionDuration !== '0s') {
      let eventCallback = function () {
        element.removeEventListener('transitionend', eventCallback);
        callback();
      };
      element.addEventListener('transitionend', eventCallback);
    } else if (computedStyle.animationName !== 'none' && computedStyle.animationPlayState === 'running') {
      let eventCallback = function () {
        element.removeEventListener('animationend', eventCallback);
        callback();
      };
      element.addEventListener('animationend', eventCallback);
    } else {
      callback();
    }
  }
  let PaperMenuContent = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _component2.layout)(_template.default), _dec3 = (0, _object.computed)('otherStyles', 'isActive'), _dec4 = (0, _object.computed)('destination'), _dec(_class = _dec2(_class = (_class2 = class PaperMenuContent extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "isActive", false);
    }
    get customStyles() {
      if (this.isActive) {
        return {};
      } else {
        return this.otherStyles;
      }
    }
    get destinationElement() {
      return document.getElementById(this.destination);
    }
    async animateIn() {
      await (0, _transitionUtils.nextTick)();
      this.set('isActive', true);
    }
    async animateOut(element) {
      let parentElement = this.renderInPlace ? element.parentElement.parentElement : element.parentElement;

      // workaround for https://github.com/miguelcobain/ember-paper/issues/1151. See also https://github.com/emberjs/ember.js/issues/18795.
      // & https://github.com/miguelcobain/ember-paper/issues/1166
      if (!parentElement) {
        parentElement = (0, _ebdGetParent.default)((0, _application.getOwner)(this));
      }
      let clone = element.cloneNode(true);
      clone.id = `${clone.id}--clone`;
      parentElement.appendChild(clone);
      await (0, _transitionUtils.nextTick)();
      if (!this.isDestroyed) {
        this.set('isActive', false);
        clone.classList.add('md-leave');
        waitForAnimations(clone, function () {
          clone.classList.remove('md-active');
          parentElement.removeChild(clone);
        });
      } else {
        parentElement.removeChild(clone);
      }
    }
    focusItem(element) {
      let focusTarget = element.querySelector('.md-menu-focus-target');

      // default to first non disabled item
      if (!focusTarget) {
        let menuItem = element.querySelector('md-menu-item:not([disabled])');
        focusTarget = menuItem && menuItem.firstElementChild;
      }
      if (focusTarget) {
        focusTarget.focus();
      }
    }
    handleKeyDown(ev) {
      switch (ev.which) {
        case _keyConstants.ESCAPE:
          this.dropdown.actions.close();
          break;
        case _keyConstants.LEFT_ARROW:
        case _keyConstants.UP_ARROW:
          ev.preventDefault();
          this.focusMenuItem(ev, -1);
          break;
        case _keyConstants.RIGHT_ARROW:
        case _keyConstants.DOWN_ARROW:
          ev.preventDefault();
          this.focusMenuItem(ev, 1);
          break;
      }
    }
    focusMenuItem(e, direction) {
      let focusTarget = e.target.closest('md-menu-item');
      do {
        if (direction > 0) {
          focusTarget = focusTarget.nextElementSibling;
        } else {
          focusTarget = focusTarget.previousElementSibling;
        }
      } while (focusTarget && !isFocusable(focusTarget));
      focusTarget = focusTarget && focusTarget.firstElementChild;
      if (focusTarget) {
        focusTarget.focus();
      }
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "customStyles", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "customStyles"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "destinationElement", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "destinationElement"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "animateIn", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "animateIn"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "animateOut", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "animateOut"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "focusItem", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "focusItem"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleKeyDown", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeyDown"), _class2.prototype), _class2)) || _class) || _class);
  function isFocusable(el) {
    // is a menu-item, doesn't have tabindex -1 and is not disabled
    return el && el.tagName === 'MD-MENU-ITEM' && el.getAttribute('tabindex') !== -1 && el.getAttribute('disabled') === null;
  }
  var _default = _exports.default = PaperMenuContent;
});