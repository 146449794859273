define("@trovedata/sunstone-ui-commons/components/trove-label/component", ["exports", "@ember/object", "@ember/component", "@trovedata/sunstone-ui-commons/components/trove-label/template", "@trovedata/sunstone-ui-commons/mixins/trove-color-mixin", "@ember/template", "@ember-decorators/component", "@ember/runloop", "ember-concurrency-decorators", "@trovedata/sunstone-ui-commons/utils/component-utils"], function (_exports, _object, _component, _template, _troveColorMixin, _template2, _component2, _runloop, _emberConcurrencyDecorators, _componentUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let TroveLabelComponent = _exports.default = (_dec = (0, _component2.layout)(_template.default), _dec2 = (0, _component2.classNames)('trove-label', 'label'), _dec3 = (0, _object.computed)('info'), _dec4 = (0, _component2.className)('md-input-shown'), _dec5 = (0, _component2.attribute)('type'), _dec6 = (0, _component2.attribute)('href'), _dec7 = (0, _component2.attribute)('target'), _dec8 = (0, _component2.attribute)('title'), _dec9 = (0, _component2.className)('md-condensed'), _dec10 = (0, _component2.className)('md-uppercase'), _dec11 = (0, _component2.className)('md-inverted'), _dec12 = (0, _component2.className)('md-raised'), _dec13 = (0, _component2.className)('md-bold'), _dec14 = (0, _component2.className)('md-disabled'), _dec15 = (0, _component2.className)('md-alternative'), _dec16 = (0, _component2.className)('md-editable'), _dec17 = (0, _component2.className)('text-ellipse'), _dec18 = (0, _component2.attribute)('style'), _dec19 = (0, _object.computed)('size', 'border', 'borderRadius', 'onClick', 'href'), _dec(_class = _dec2(_class = (_class2 = class TroveLabelComponent extends _component.default.extend(_troveColorMixin.default).reopenClass({
    positionalParams: ['label']
  }) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "tooltip", null);
      _defineProperty(this, "side", 'top');
      // @ts-ignore
      _defineProperty(this, "status", 'default');
      _defineProperty(this, "size", 15);
      _defineProperty(this, "border", void 0);
      _defineProperty(this, "onClick", void 0);
      _defineProperty(this, "labelToEdit", '');
      _defineProperty(this, "bubbles", void 0);
      _defineProperty(this, "label", void 0);
      _defineProperty(this, "borderRadius", void 0);
      _defineProperty(this, "info", void 0);
      _defineProperty(this, "opacity", void 0);
      _initializerDefineProperty(this, "showInput", _descriptor, this);
      _initializerDefineProperty(this, "type", _descriptor2, this);
      _initializerDefineProperty(this, "href", _descriptor3, this);
      _initializerDefineProperty(this, "target", _descriptor4, this);
      _initializerDefineProperty(this, "title", _descriptor5, this);
      _initializerDefineProperty(this, "hoverable", _descriptor6, this);
      _initializerDefineProperty(this, "condensed", _descriptor7, this);
      _initializerDefineProperty(this, "uppercase", _descriptor8, this);
      _initializerDefineProperty(this, "inverted", _descriptor9, this);
      _initializerDefineProperty(this, "raised", _descriptor10, this);
      _initializerDefineProperty(this, "bold", _descriptor11, this);
      _initializerDefineProperty(this, "disabled", _descriptor12, this);
      _initializerDefineProperty(this, "alternative", _descriptor13, this);
      _initializerDefineProperty(this, "onEditSave", _descriptor14, this);
      _initializerDefineProperty(this, "ellipse", _descriptor15, this);
    }
    get infoShade() {
      return (0, _componentUtils.calcColorVar)('md-info', this.info);
    }
    get labelStyle() {
      let {
        size
      } = this;
      let {
        border
      } = this;
      let {
        borderRadius
      } = this;
      let labelStyle = '';
      if (size) {
        labelStyle = `
        min-height: ${size}px;
        font-size: ${size}px;
        line-height: ${size}px;
      `;
      }
      if (this.opacity !== undefined) {
        labelStyle = `${labelStyle}--opacity: ${this.opacity};`;
      }
      labelStyle = `${labelStyle}border-radius: ${(this.onClick || this.href) && this.borderRadius === 14 ? 4 : borderRadius}px;`;
      if (border) {
        labelStyle = `${labelStyle}border: 3px solid ${border};`;
      }
      return (0, _template2.htmlSafe)(labelStyle);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs();
      // @ts-ignore
      this.set('hoverable', this.onClick || this.onEditSave ? true : false);
      if (this.borderRadius === undefined && (this.raised === true || this.inverted === true)) {
        this.set('borderRadius', 14);
      }
      if (this.href) {
        this.setProperties({
          hoverable: true,
          tagName: 'a',
          type: null
        });
      }
    }
    editSave() {
      // @ts-ignore
      return this.editSaveTask.perform(...arguments);
    }
    *editSaveTask(value, onEditSave) {
      if (onEditSave) {
        yield onEditSave(value);
        this.set('showInput', false);
      }
    }
    click(event) {
      if (this.onClick && !this.disabled && typeof this.onClick === 'function') {
        this.onClick(event);
      }
      // @ts-ignore
      if (this.onEditSave && !this.showInput) {
        this.set('showInput', true);
        this.set('labelToEdit', this.label);
        (0, _runloop.scheduleOnce)('afterRender', this, function () {
          let $editInput = this.element.querySelector('input');
          $editInput?.focus();
          $editInput?.select();
        });
      }

      // Prevent bubbling, if specified. If undefined, the event will bubble.
      return this.bubbles;
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "infoShade", [_component2.className, _dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "infoShade"), _class2.prototype), _descriptor = _applyDecoratedDescriptor(_class2.prototype, "showInput", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "type", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "href", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "target", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "title", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "hoverable", [_component2.className], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "condensed", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "uppercase", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "inverted", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "raised", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "bold", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "disabled", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class2.prototype, "alternative", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class2.prototype, "onEditSave", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class2.prototype, "ellipse", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "labelStyle", [_dec18, _dec19], Object.getOwnPropertyDescriptor(_class2.prototype, "labelStyle"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "editSave", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "editSave"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "editSaveTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "editSaveTask"), _class2.prototype), _class2)) || _class) || _class);
});