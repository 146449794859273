define("ember-keyboard/services/keyboard", ["exports", "@ember/service", "@ember/application", "@ember/object", "@ember/runloop", "@ember/debug", "ember-keyboard/listeners/key-events", "ember-keyboard/utils/handle-key-event", "ember-keyboard/utils/sort"], function (_exports, _service, _application, _object, _runloop, _debug, _keyEvents, _handleKeyEvent, _sort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let KeyboardService = _exports.default = (_class = class KeyboardService extends _service.default {
    get activeResponders() {
      let {
        registeredResponders
      } = this;
      return Array.from(registeredResponders).filter(r => r.keyboardActivated);
    }
    get sortedResponders() {
      return this.activeResponders.sort((a, b) => {
        if (this.isPropagationEnabled) {
          return (0, _sort.reverseCompareProp)(a, b, 'keyboardPriority');
        } else {
          let compareValue = (0, _sort.reverseCompareProp)(a, b, 'keyboardFirstResponder', Boolean);
          if (compareValue === 0) {
            return (0, _sort.reverseCompareProp)(a, b, 'keyboardPriority');
          }
          return compareValue;
        }
      });
    }
    get firstResponders() {
      return this.sortedResponders.filter(r => r.keyboardFirstResponder);
    }
    get normalResponders() {
      return this.sortedResponders.filter(r => !r.keyboardFirstResponder);
    }
    constructor(...args) {
      super(...args);
      _defineProperty(this, "isPropagationEnabled", false);
      _defineProperty(this, "registeredResponders", new Set());
      if (typeof FastBoot !== 'undefined') {
        return;
      }
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment') || {};
      let emberKeyboardConfig = config.emberKeyboard || {};
      const isPropagationEnabled = Boolean(emberKeyboardConfig.propagation);
      this.isPropagationEnabled = isPropagationEnabled;
      (false && !(isPropagationEnabled) && (0, _debug.deprecate)('The old event propagation semantics have been deprecated. ' + 'You should set `emberKeyboard.propagation = true` in `config/environment.js`.', isPropagationEnabled, {
        id: 'ember-keyboard.old-propagation-model',
        for: 'ember-keyboard',
        since: '6.0.4',
        until: '7.0.0',
        url: 'https://adopted-ember-addons.github.io/ember-keyboard/deprecations#old-propagation-model'
      }));
      this._listeners = emberKeyboardConfig.listeners || ['keyUp', 'keyDown', 'keyPress'];
      this._listeners = this._listeners.map(listener => listener.toLowerCase());
      this._listeners.forEach(type => {
        document.addEventListener(type, this._respond);
      });
    }
    willDestroy(...args) {
      super.willDestroy(...args);
      if (typeof FastBoot !== 'undefined') {
        return;
      }
      this._listeners.forEach(type => {
        document.removeEventListener(type, this._respond);
      });
    }
    _respond(event) {
      (0, _runloop.run)(() => {
        if (this.isPropagationEnabled) {
          let {
            firstResponders,
            normalResponders
          } = this;
          (0, _handleKeyEvent.handleKeyEventWithPropagation)(event, {
            firstResponders,
            normalResponders
          });
        } else {
          let {
            sortedResponders
          } = this;
          (0, _handleKeyEvent.handleKeyEventWithLaxPriorities)(event, sortedResponders);
        }
      });
    }
    register(responder) {
      this.registeredResponders.add(responder);
    }
    unregister(responder) {
      this.registeredResponders.delete(responder);
    }
    keyDown(...args) {
      return (0, _keyEvents.keyDown)(...args);
    }
    keyPress(...args) {
      return (0, _keyEvents.keyPress)(...args);
    }
    keyUp(...args) {
      return (0, _keyEvents.keyUp)(...args);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "_respond", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_respond"), _class.prototype), _class);
});