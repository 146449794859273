define("@trovedata/sunstone-ui-commons/adapters/user", ["exports", "@ember/service", "@trovedata/sunstone-ui-commons/adapters/application-json-api"], function (_exports, _service, _applicationJsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let User = _exports.default = (_class = class User extends _applicationJsonApi.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "apiGateway", _descriptor, this);
      _initializerDefineProperty(this, "config", _descriptor2, this);
    }
    init() {
      super.init();
      const {
        config
      } = this;
      if (config.get('trove.userManagerBaseUrl')) {
        this.host = config.get('trove.userManagerBaseUrl');
        this.namespace = '';
        return;
      }
      const id = this.getTargetBackendServiceId();
      const serviceInfo = this.apiGateway.getServiceById(id);
      if (serviceInfo) {
        if (serviceInfo.baseRoute.startsWith('/')) {
          // The service is behind the api-gateway
          this.host = config.get('trove.apiGatewayBaseUrl');
          this.namespace = serviceInfo.baseRoute.substring(1, serviceInfo.baseRoute.length - 1);
        } else {
          // The service is on a unique host
          this.host = config.get('environment') === 'development' ? config.get('trove.userManagerBaseUrl') : `https://${serviceInfo.baseRoute}`;
          this.namespace = '';
        }
      } else {
        // We were unable to get serviceinfo from the api-gateway, fallback to the
        // old logic from the config
        this.host = this.config.get('trove.api.host');
        this.namespace = this.getFallbackNamespace();
        console.warn(`serviceInfo for backend service ${this.getTargetBackendServiceId()} not found, defaulting to ${this.namespace}`);
      }
    }
    getFallbackNamespace() {
      return this.config.get('trove.troveUserManager.namespace');
    }
    getTargetBackendServiceId() {
      return 'user-manager-service';
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "apiGateway", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "config", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class); // DO NOT DELETE: this is how TypeScript knows how to look up your adapters.
});