define("@trovedata/sunstone-ui-commons/components/sunstone-map-geojson/component", ["exports", "@ember/component", "@ember/array", "@ember/utils", "@ember/object", "jquery", "@trovedata/sunstone-ui-commons/components/sunstone-map-geojson/template", "ember-copy", "@ember-decorators/component", "@trovedata/sunstone-ui-commons/utils/component-utils"], function (_exports, _component, _array, _utils, _object, _jquery, _template, _emberCopy, _component2, _componentUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _class2; // @ts-ignore
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function PolyLineFill(poly, map, fill, stroke) {
    let bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < poly.length; i++) {
      bounds.extend(poly[i]);
    }

    //initialize all properties.
    this.bounds_ = bounds;
    this.map_ = map;
    this.div_ = null;
    this.poly_ = poly;
    this.polysvg_ = null;
    this.fill_ = fill;
    this.stroke_ = stroke;

    // Explicitly call setMap on this overlay
    this.setMap(map);
  }
  let SunstoneMapGeoJson = _exports.default = (_dec = (0, _component2.layout)(_template.default), _dec(_class = (_class2 = class SunstoneMapGeoJson extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "geoJson", void 0);
      _defineProperty(this, "paths", (0, _array.A)([]));
      _defineProperty(this, "overlays", (0, _array.A)([]));
      _defineProperty(this, "infoWindowContent", (0, _array.A)([]));
      _defineProperty(this, "infoWindowHeader", void 0);
      _defineProperty(this, "infoWindow", void 0);
      _defineProperty(this, "contextWindow", void 0);
      _defineProperty(this, "eventListener", false);
      _defineProperty(this, "bounds", new google.maps.LatLngBounds());
      _defineProperty(this, "map", void 0);
      _defineProperty(this, "eventListeners", void 0);
      _defineProperty(this, "addfeatureListener", void 0);
      _defineProperty(this, "clickListener", void 0);
      _defineProperty(this, "dblClickListener", void 0);
      _defineProperty(this, "mouseover", void 0);
      _defineProperty(this, "mouseout", void 0);
      _defineProperty(this, "setFeatures", void 0);
      _defineProperty(this, "fireWhenFeaturesCreated", void 0);
      _defineProperty(this, "uniqueIdentifier", void 0);
      _defineProperty(this, "dblclick", void 0);
      _defineProperty(this, "clickTimeout", void 0);
      _defineProperty(this, "enableSearch", false);
      _defineProperty(this, "enableContextWindow", false);
      _defineProperty(this, "fitBounds", true);
      _defineProperty(this, "useClick", void 0);
      _defineProperty(this, "useMouseover", void 0);
      _defineProperty(this, "onClick", void 0);
      _defineProperty(this, "onFetchContext", void 0);
      _defineProperty(this, "onMouseout", void 0);
      _defineProperty(this, "onMouseover", void 0);
      _defineProperty(this, "onReady", void 0);
    }
    didRender() {
      super.didRender();
      let map = this.map;
      let geoJson = (0, _object.get)(this, 'geoJson');
      if (map && geoJson) {
        if (this.onFetchContext) this.onFetchContext(this);
        (0, _object.set)(this, 'bounds', new google.maps.LatLngBounds());
        if (this.overlays && this.overlays.length) {
          this.overlays.forEach(overlay => {
            overlay.setMap(null);
          });
        }
        if (this.enableContextWindow && !this.contextWindow) {
          const contextWindowDiv = document.createElement("div");
          contextWindowDiv.style.margin = "10px";
          contextWindowDiv.style.pointerEvents = "none";
          map?.controls[google.maps.ControlPosition.LEFT_BOTTOM]?.push(contextWindowDiv);
          (0, _object.set)(this, 'contextWindow', contextWindowDiv);
        }
        if (this.enableSearch) {
          // Create the search box and link it to the UI element.
          const input = document.getElementById('map-search-input');
          const searchBox = new google.maps.places.SearchBox(input);
          map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
          // Bias the SearchBox results towards current map's viewport.
          map.addListener("bounds_changed", () => {
            searchBox.setBounds(map.getBounds());
          });
          let markers = [];
          // Listen for the event fired when the user selects a prediction and retrieve
          // more details for that place.
          searchBox.addListener("places_changed", () => {
            const places = searchBox.getPlaces();
            if (places.length == 0) return;
            // Clear out the old markers.
            markers.forEach(marker => {
              // @ts-ignore
              marker.setMap(null);
            });
            markers = [];
            // For each place, get the icon, name and location.
            const bounds = new google.maps.LatLngBounds();
            places.forEach(place => {
              if (!place.geometry || !place.geometry.location) {
                console.log("Returned place contains no geometry");
                return;
              }
              const icon = {
                url: place.icon,
                size: new google.maps.Size(71, 71),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(17, 34),
                scaledSize: new google.maps.Size(25, 25)
              };

              // Create a marker for each place.
              markers.push(
              // @ts-ignore
              new google.maps.Marker({
                map,
                icon,
                title: place.name,
                position: place.geometry.location
              }));
              if (place.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(place.geometry.viewport);
              } else {
                bounds.extend(place.geometry.location);
              }
            });
            map.fitBounds(bounds);
          });
        }
        if (!(0, _object.get)(this, 'eventListeners')) {
          PolyLineFill.prototype = new google.maps.OverlayView();
          PolyLineFill.prototype.onAdd = function () {
            // Create the DIV and set some basic attributes.
            let div = document.createElement('div');
            div.style.borderStyle = 'none';
            div.style.borderWidth = '0px';
            div.style.position = 'absolute';

            //create the svg element
            let svgns = "http://www.w3.org/2000/svg";
            let svg = document.createElementNS(svgns, "svg");
            svg.setAttributeNS(null, "preserveAspectRatio", "xMidYMid meet");
            svg.setAttributeNS(null, "height", "100%");
            svg.setAttributeNS(null, "width", "100%");
            let def = document.createElementNS(svgns, "defs");

            //create the pattern fill
            let pattern = document.createElementNS(svgns, "pattern");
            pattern.setAttributeNS(null, "id", "lineFill");
            pattern.setAttributeNS(null, "patternUnits", "userSpaceOnUse");
            pattern.setAttributeNS(null, "patternTransform", "rotate(-45)");
            pattern.setAttributeNS(null, "height", "5");
            pattern.setAttributeNS(null, "width", "5");
            def.appendChild(pattern);
            let rect = document.createElementNS(svgns, "rect");
            rect.setAttributeNS(null, "id", "rectFill");
            rect.setAttributeNS(null, "fill", this.fill_);
            rect.setAttributeNS(null, "fill-opacity", "0.3");
            rect.setAttributeNS(null, "stroke", this.stroke_);
            rect.setAttributeNS(null, "stroke-dasharray", "7,7");
            rect.setAttributeNS(null, "stroke-width", "2");
            rect.setAttributeNS(null, "height", "7");
            rect.setAttributeNS(null, "width", "7");
            pattern.appendChild(rect);
            svg.appendChild(def);

            //add polygon to the div
            let p = document.createElementNS(svgns, "polygon");
            p.setAttributeNS(null, "fill", "url(#lineFill)");
            // p.setAttributeNS(null, "stroke", "#ffffff");
            // p.setAttributeNS(null, "stroke-width", "5");
            //set a reference to this element;
            this.polysvg_ = p;
            svg.appendChild(p);
            div.appendChild(svg);

            // Set the overlay's div_ property to this DIV
            this.div_ = div;

            // We add an overlay to a map via one of the map's panes.
            // We'll add this overlay to the overlayLayer pane.
            let panes = this.getPanes();
            panes.overlayLayer.appendChild(div);
          };
          PolyLineFill.prototype.AdjustPoints = function () {
            //adjust the polygon points based on the projection.
            // console.log("AdjustPoints");
            let proj = this.getProjection();
            let sw = proj.fromLatLngToDivPixel(this.bounds_.getSouthWest());
            let ne = proj.fromLatLngToDivPixel(this.bounds_.getNorthEast());
            let points = "";
            for (let i = 0; i < this.poly_.length; i++) {
              let point = proj.fromLatLngToDivPixel(this.poly_[i]);
              if (i == 0) {
                points += point.x - sw.x + ", " + (point.y - ne.y);
              } else {
                points += " " + (point.x - sw.x) + ", " + (point.y - ne.y);
              }
            }
            return points;
          };
          PolyLineFill.prototype.draw = function () {
            // console.log("draw");
            // Size and position the overlay. We use a southwest and northeast
            // position of the overlay to peg it to the correct position and size.
            // We need to retrieve the projection from this overlay to do this.
            let overlayProjection = this.getProjection();

            // Retrieve the southwest and northeast coordinates of this overlay
            // in latlngs and convert them to pixels coordinates.
            // We'll use these coordinates to resize the DIV.
            let sw = overlayProjection.fromLatLngToDivPixel(this.bounds_.getSouthWest());
            let ne = overlayProjection.fromLatLngToDivPixel(this.bounds_.getNorthEast());

            // Resize the image's DIV to fit the indicated dimensions.
            let div = this.div_;
            div.style.left = sw.x + 'px';
            div.style.top = ne.y + 'px';
            div.style.width = ne.x - sw.x + 'px';
            div.style.height = sw.y - ne.y + 'px';
            this.polysvg_.setAttributeNS(null, "points", this.AdjustPoints());
          };
          PolyLineFill.prototype.onRemove = function () {
            if (this.div_) {
              this.div_.parentNode.removeChild(this.div_);
              this.div_ = null;
            }
          };
          // @ts-ignore
          window.BW = {};
          // @ts-ignore
          window.BW.PolyLineFill = PolyLineFill;
          (0, _object.set)(this, 'eventListeners', true);
          let addfeatureListener = map.data.addListener('addfeature', e => {
            e.feature.setProperty('center', new google.maps.LatLngBounds());
            if (e.feature.getProperty('bounds') !== false) {
              this.send('processPoints', e.feature.getGeometry(), (0, _object.get)(this, 'bounds'), e.feature.getProperty('center'), e.feature.getProperty('boundaryType'));
            }
            if (this.paths && this.paths.length) {
              let paths = (0, _emberCopy.copy)((0, _object.get)(this, 'paths'));
              // @ts-ignore
              let overlay = new window.BW.PolyLineFill(paths, map, e.feature.getProperty('stripeFill'), e.feature.getProperty('stripeColor'));
              (0, _object.get)(this, 'overlays').pushObject(overlay);
              (0, _object.get)(this, 'paths').clear();
            }
            if (this.fitBounds) map.fitBounds((0, _object.get)(this, 'bounds'));
          });
          (0, _object.set)(this, 'addfeatureListener', addfeatureListener);
          let infoWindow = new google.maps.InfoWindow({
            content: '',
            disableAutoPan: true,
            pixelOffset: new google.maps.Size(0, -10)
          });
          (0, _object.set)(this, 'infoWindow', infoWindow);
          let mouseover = map.data.addListener('mouseover', event => {
            let featureName = event.feature.getProperty('name');
            map.data.revertStyle();
            map.data.forEach(aFeature => {
              if (aFeature.getProperty('name') === featureName) {
                const strokeWeight = event.feature.getProperty('strokeWeight');
                map.data.overrideStyle(aFeature, {
                  strokeWeight: strokeWeight ? strokeWeight * 2 : 4
                });
              }
            });

            // If context windows are enabled, replace info windows.
            if (this.enableContextWindow) {
              this.displayGeoContextWindow(event);
            }

            // Otherwise, show a typical tooltip.
            else if (this.useMouseover(event.feature)) {
              this.mouseOverGeo(event);
            }
          });
          const _this = this;
          google.maps.event.addListenerOnce(map, 'idle', function () {
            // Do something only the first time the map is loaded
            if (_this.onReady) _this.onReady();
          });
          google.maps.event.addListener(infoWindow, 'domready', function () {
            let iwOuter = (0, _jquery.default)('.gm-style-iw');
            let iwBackground = iwOuter.prev();
            let noHeader = this.infoWindowHeader ? '' : 'no-header';
            iwBackground.children(':nth-child(3)').find('div').children().css({
              'box-shadow': '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)',
              'z-index': '1'
            });
            let iwCloseBtn = iwOuter.find('button');
            iwCloseBtn.css({
              opacity: '1',
              right: '0px',
              top: '0px',
              width: '24px',
              height: '24px'
            });
            const componentProps = this.feature.getProperty('componentInfoWindowProps');
            const $parentContainer = document.querySelector('.gm-style-iw-a');
            if (componentProps) {
              const $container = document.querySelector('.gm-style-iw.gm-style-iw-c');
              const $componentContainer = document.querySelector('.component-container');
              $parentContainer?.setAttribute('style', `${$parentContainer.getAttribute('style')} --tick-background: transparent;`);

              // console.log(initGlimmerComponent(_this, componentProps.componentName, componentProps.componentProps));

              const component = componentProps.glimmer ? (0, _componentUtils.initGlimmerComponent)(_this, componentProps.componentName, componentProps.componentProps) : (0, _componentUtils.default)(_this, componentProps.componentName, componentProps.componentProps).append();
              document.querySelector('body')?.removeChild(component.element);
              $componentContainer?.appendChild(component.element);
              $container?.setAttribute('style', `
              ${$container.getAttribute('style')}
              background-color: transparent !important;
              ${$componentContainer ? 'min-width: ' + $componentContainer.clientWidth + 'px !important' : ''}
            `);
            }
            $parentContainer?.setAttribute('style', `${$parentContainer.getAttribute('style')} opacity: 1;`);
            iwCloseBtn.html(`<i class="material-icons info-window-close ${noHeader}">close</i>`);
          });
          (0, _object.set)(this, 'mouseover', mouseover);
          let mouseout = map.data.addListener('mouseout', () => {
            if (this.onMouseout) this.onMouseout();
            if (this.enableContextWindow && this.contextWindow?.firstChild) this.contextWindow?.removeChild(this.contextWindow.firstChild);
            infoWindow.close();
            map.data.revertStyle();
          });
          (0, _object.set)(this, 'mouseout', mouseout);
          let clickListener = map.data.addListener('click', event => {
            if (this.useClick(event.feature)) {
              this.clickGeo(event);
            } else {
              if (!this.enableContextWindow) {
                this.mouseOverGeo(event);
              }
            }
          });
          (0, _object.set)(this, 'clickListener', clickListener);
          let dblClickListener = map.data.addListener('dblclick', event => {
            if (this.clickTimeout) {
              clearTimeout(this.clickTimeout);
            }
            let dblclick = this.dblclick;
            if (dblclick) {
              let featureName = event.feature.getProperty('name');
              let allLayers = (0, _array.A)([]);
              let uniqueIds = (0, _array.A)([]);
              let uniqueIdentifier = (0, _object.get)(this, 'uniqueIdentifier');
              map.data.forEach(aFeature => {
                if (aFeature.getProperty('name') === featureName) {
                  aFeature.setProperty('isColorful', !aFeature.getProperty('isColorful'));
                  if (!allLayers.includes(aFeature) && (!uniqueIdentifier || !uniqueIds.includes(aFeature.getProperty(`${uniqueIdentifier}`)))) {
                    allLayers.pushObject(aFeature);
                    if (uniqueIdentifier) {
                      uniqueIds.pushObject(aFeature.getProperty(`${uniqueIdentifier}`));
                    }
                  }
                }
              });
              dblclick(allLayers);
            }
          });
          (0, _object.set)(this, 'dblClickListener', dblClickListener);
        }
        map.data.forEach(feature => {
          map.data.remove(feature);
        });
        if ((0, _utils.typeOf)(geoJson) === 'string') {
          map.data.loadGeoJson(geoJson);
        } else {
          map.data.addGeoJson(geoJson);
        }
        map.data.setStyle(function (feature) {
          let color = feature.getProperty('fillColor');
          let strokeColor = feature.getProperty('borderColor');
          let strokeWeight = feature.getProperty('strokeWeight');
          let zIndex = feature.getProperty('zIndex');
          let icon = feature.getProperty('icon');
          let weight = feature.getProperty('weight');
          if (feature.getProperty('isColorful')) {
            color = feature.getProperty('selectedColor') || feature.getProperty('fillColor');
            weight = feature.getProperty('selectedWeight') || feature.getProperty('weight');
          }
          return {
            fillColor: color,
            strokeColor: strokeColor ? strokeColor : color,
            strokeWeight: strokeWeight ? strokeWeight : 2,
            fillOpacity: weight,
            icon,
            zIndex: zIndex !== undefined ? zIndex : 1
          };
        });
        if ((0, _object.get)(this, 'setFeatures')) {
          (0, _object.get)(this, 'setFeatures')(map.data);
        }
        if ((0, _object.get)(this, 'fireWhenFeaturesCreated')) {
          (0, _object.get)(this, 'fireWhenFeaturesCreated')(map.data);
        }
      }
    }
    displayGeoContextWindow(event) {
      if (this.onMouseover) this.onMouseover(event);
      const _this = this;
      const contextWindow = (0, _object.get)(_this, 'contextWindow');
      const componentProps = event.feature.getProperty('componentInfoWindowProps');
      if (componentProps) {
        const component = componentProps.glimmer ? (0, _componentUtils.initGlimmerComponent)(_this, componentProps.componentName, componentProps.componentProps) : (0, _componentUtils.default)(_this, componentProps.componentName, componentProps.componentProps).append();
        if (contextWindow?.firstChild) {
          contextWindow.removeChild(contextWindow.firstChild);
        }
        contextWindow?.appendChild(component.element);
      }
    }
    mouseOverGeo(event) {
      if (this.onMouseover) this.onMouseover(event);
      let content = '<div id="iw-container">';
      if (this.infoWindowHeader) {
        content = `${content}<div class="iw-title">${event.feature.getProperty(this.infoWindowHeader.titleKey)}</div>`;
      }
      let dataKey = Object.keys(event.feature).find(key => {
        let value = event.feature[key];
        return value && value.center;
      });
      let noHeader = this.infoWindowHeader ? '' : 'no-header';
      content = `${content}<div class="iw-content ${noHeader}">`;
      if (this.infoWindowContent.length) {
        this.infoWindowContent.forEach(item => {
          let isDisplayed = event.feature[dataKey][item.isDisplayed];
          if (isDisplayed === undefined || isDisplayed) {
            content += item.displayName ? `<span>${item.displayName}: ${event.feature[dataKey][item.key]}</span><br>` : `<span>${event.feature[dataKey][item.key]}</span><br>`;
          }
        });
      }
      this.infoWindow.setContent(this.infoWindowContent.length ? content : '<div class="component-container"></div>');
      const pixelOffset = this.getInfoWindowOffset(this.map, event.latLng);
      this.infoWindow.setOptions({
        pixelOffset
      });
      let anchor = new google.maps.MVCObject();
      anchor.setValues({
        position: event.latLng || event.feature.getProperty('center').getCenter(),
        anchorPoint: new google.maps.Point(0, 0)
      });
      if (this.infoWindowContent && this.infoWindowContent.length || event.feature.getProperty('componentInfoWindowProps')) {
        this.infoWindow.feature = event.feature;
        this.infoWindow.open(this.map, anchor);
      }
    }
    getInfoWindowOffset(map, marker) {
      const center = this.getPixelFromLatLng(map.getCenter());
      const point = this.getPixelFromLatLng(marker);
      let quadrant = '';
      let offset;
      quadrant += point.y > center.y ? 'b' : 't';
      quadrant += point.x < center.x ? 'l' : 'r';
      if (quadrant == 'tr') {
        offset = new google.maps.Size(-170, 185);
      } else if (quadrant == 'tl') {
        offset = new google.maps.Size(70, 185);
      } else if (quadrant == 'br') {
        offset = new google.maps.Size(-170, 20);
      } else if (quadrant == 'bl') {
        offset = new google.maps.Size(70, 20);
      }
      return offset;
    }
    getPixelFromLatLng(latLng) {
      const projection = this.map.getProjection();
      const point = projection.fromLatLngToPoint(latLng);
      return point;
    }
    clickGeo(event) {
      let clickTimeout;
      let onClick = (0, _object.get)(this, 'onClick');
      if (onClick) {
        clickTimeout = setTimeout(() => {
          onClick(event.feature);
        }, 200);
      }
      this.set('clickTimeout', clickTimeout);
    }
    willDestroyElement() {
      super.willDestroyElement();
      let map = (0, _object.get)(this, 'map');
      let infoWindow = (0, _object.get)(this, 'infoWindow');
      let clickListener = (0, _object.get)(this, 'clickListener');
      let dblClickListener = (0, _object.get)(this, 'dblClickListener');
      let mouseover = (0, _object.get)(this, 'mouseover');
      let mouseout = (0, _object.get)(this, 'mouseout');
      let addfeatureListener = (0, _object.get)(this, 'addfeatureListener');
      let enableContextWindow = (0, _object.get)(this, 'enableContextWindow');
      if (map) {
        map.data.forEach(function (feature) {
          map.data.remove(feature);
        });
      }
      if (clickListener) {
        clickListener.remove();
      }
      if (dblClickListener) {
        dblClickListener.remove();
      }
      if (mouseover) {
        mouseover.remove();
      }
      if (mouseout) {
        mouseout.remove();
      }
      if (addfeatureListener) {
        // addfeatureListener.remove();
      }
      if (infoWindow) {
        infoWindow.close();
      }
      if (enableContextWindow) {
        (0, _object.set)(this, 'contextWindow', undefined);
        map?.controls[google.maps.ControlPosition.LEFT_BOTTOM]?.clear();
      }
      (0, _object.set)(this, 'eventListeners', false);
    }
    processPoints(geometry, bounds, featureCenter, boundaryType) {
      if (geometry instanceof google.maps.LatLng) {
        if (boundaryType == 'BOTH') {
          this.paths.pushObject(geometry);
        }
        bounds.extend(geometry);
        featureCenter.extend(geometry);
      } else if (geometry instanceof google.maps.Data.Point) {
        if (boundaryType == 'BOTH') {
          this.paths.pushObject(geometry.get());
        }
        bounds.extend(geometry.get());
        featureCenter.extend(geometry.get());
      } else {
        geometry.getArray().forEach(g => {
          this.send('processPoints', g, bounds, featureCenter, boundaryType);
        });
      }
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "processPoints", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "processPoints"), _class2.prototype), _class2)) || _class);
});