define("ember-paper/components/paper-autocomplete/eps-trigger/component", ["exports", "@ember/component", "ember-paper/components/paper-autocomplete/eps-trigger/template", "@ember-decorators/component", "@ember/object", "ember-paper/utils/unwrap-proxy"], function (_exports, _component, _template, _component2, _object, _unwrapProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let PaperSelectEpsTrigger = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _component2.layout)(_template.default), _dec3 = (0, _object.computed)('select.{searchText,selected}'), _dec4 = (0, _object.computed)('allowClear', 'select.disabled', 'resetButtonDestroyed'), _dec(_class = _dec2(_class = (_class2 = class PaperSelectEpsTrigger extends _component.default {
    get text() {
      let selected = (0, _unwrapProxy.default)(this.select.selected);
      if (selected) {
        return this.getSelectedAsText();
      }
      return this.select.searchText;
    }
    getSelectedAsText() {
      let labelPath = this.extra.labelPath;
      if (labelPath) {
        return (0, _object.get)(this.select.selected, labelPath);
      } else {
        return this.select.selected;
      }
    }
    handleKeydown(e) {
      let isLetter = e.keyCode >= 48 && e.keyCode <= 90 || e.keyCode === 32; // Keys 0-9, a-z or SPACE
      let isSpecialKeyWhileClosed = !isLetter && !this.select.isOpen && [13, 27, 38, 40].indexOf(e.keyCode) > -1;
      if (isLetter || isSpecialKeyWhileClosed) {
        e.stopPropagation();
      }
    }
    _onInput(value) {
      this.onInput({
        target: {
          value
        }
      });
    }
    didInsert(element) {
      this.triggerElement = element;
    }
    clear(e) {
      e.stopPropagation();
      if (this.onClear) {
        this.onClear();
      } else {
        this.select.actions.select(null);
        this.onInput({
          target: {
            value: ''
          }
        });
      }
      this.onFocus(e);
      this.triggerElement.querySelector('input').focus();
    }
    get showingClearButton() {
      // make room for clear button:
      // - if we're enabled
      // - or if we're disabled but the button still wasn't destroyed
      return this.allowClear && (!this.select.disabled || this.select.disabled && !this.resetButtonDestroyed);
    }
    resetButtonDestroyed() {
      if (this.disabled) {
        this.set('resetButtonDestroyed', true);
      }
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "text", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "text"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleKeydown", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeydown"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_onInput", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_onInput"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "didInsert"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "clear", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "clear"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "showingClearButton", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "showingClearButton"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "resetButtonDestroyed", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "resetButtonDestroyed"), _class2.prototype), _class2)) || _class) || _class);
  var _default = _exports.default = PaperSelectEpsTrigger;
});