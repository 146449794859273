define("@trovedata/sunstone-ui-commons/services/sunstone-ajax", ["exports", "@ember/object", "ember-ajax/services/ajax", "ember-ajax/errors", "@ember/service", "@ember/utils", "@ember/polyfills"], function (_exports, _object, _ajax, _errors, _service, _utils, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let SunstoneAjax = _exports.default = (_dec = (0, _object.computed)(), _class = class SunstoneAjax extends _ajax.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "config", _descriptor, this);
      _defineProperty(this, "namespace", 'sunstone/api');
    }
    // @service public session;

    _getFullHeadersHash(headers) {
      const classHeaders = this.get('headers');
      const _headers = (0, _polyfills.assign)({}, classHeaders);
      return (0, _polyfills.assign)(_headers, headers);
    }
    normalizeErrorResponse(status, _headers, payload) {
      if (payload && typeof payload === 'object' && payload.errors) {
        return payload.errors;
      } else {
        return [{
          error: payload.error,
          status: `${status}`,
          title: 'The backend responded with an error',
          detail: payload.cause ? payload.cause : payload.message
        }];
      }
    }
    handleResponse(status, headers, payload, requestData) {
      let _payload = super.handleResponse(status, headers, payload, requestData);
      if (!this.session.isDestroyed && _payload instanceof _errors.UnauthorizedError && this.session.get('isAuthenticated')) {
        this.session.invalidate();
      }
      return payload;
    }
    get host() {
      let host = this.get('config.trove.api.host');
      return `${(0, _utils.isEmpty)(host) ? '/' : host}`;
    }
    // @ts-ignore
    get headers() {
      let headers = {
        'Accept': 'application/hal+json,application/json'
      };
      let {
        access_token
      } = this.get('session.data.authenticated') ? this.get('session.data.authenticated') : {
        access_token: undefined
      };
      if (access_token) {
        headers['Authorization'] = `Bearer ${access_token}`;
      }
      return headers;
    }
    options(url, options = {}) {
      if (!options.xhrFields) {
        options.xhrFields = {
          withCredentials: true
        };
      }
      options = (0, _polyfills.assign)({}, options);
      options.url = this._buildURL(url, options);
      options.type = options.type || 'GET';
      options.dataType = options.dataType || 'json';
      options.contentType = (0, _utils.isEmpty)(options.contentType) ? this.get('contentType') : options.contentType;
      if (this._shouldSendHeaders(options)) {
        options.headers = this._getFullHeadersHash(options.headers);
      } else {
        options.headers = options.headers || {};
      }
      return options;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "config", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "host", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "host"), _class.prototype), _class);
});