define("@trovedata/sunstone-ui-commons/components/paper-select-multiple-before-options/component", ["exports", "@ember/object", "ember-paper/components/paper-select/search/component", "@trovedata/sunstone-ui-commons/components/paper-select-multiple-before-options/template", "@ember/array", "@ember-decorators/component"], function (_exports, _object, _component, _template, _array, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let PaperSelectMultipleBeforeOptionsComponent = _exports.default = (_dec = (0, _component2.layout)(_template.default), _dec2 = (0, _object.computed)('select.options.[]'), _dec(_class = (_class2 = class PaperSelectMultipleBeforeOptionsComponent extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "select", void 0);
      _defineProperty(this, "actions", {
        selectAll(select) {
          if (select) {
            (0, _object.get)(this, 'flatOptions').forEach(option => {
              if (!this.select?.selected?.includes(option)) {
                this.select.actions.choose(option);
              }
            });
          } else {
            this.select?.selected.forEach(option => {
              this.select.actions.choose(option);
            });
          }
        },
        onKeydown() {}
      });
    }
    get flatOptions() {
      let flatOptions = (0, _array.A)(this.select?.options);
      if (flatOptions.get('firstObject')?.groupName) {
        flatOptions = flatOptions.reduce((flattened, optionGroup) => {
          flattened.pushObjects((0, _object.get)(optionGroup, 'options'));
          return flattened;
        }, (0, _array.A)([]));
      }
      return flatOptions;
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "flatOptions", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "flatOptions"), _class2.prototype), _class2)) || _class);
});