define("@trovedata/sunstone-ui-commons/components/toggle-group/component", ["exports", "@ember/component", "@trovedata/sunstone-ui-commons/components/toggle-group/template", "@trovedata/sunstone-ui-commons/mixins/trove-color-mixin", "ember-composability-tools", "@ember/object", "@ember-decorators/object", "@ember/template", "@ember-decorators/component"], function (_exports, _component, _template, _troveColorMixin, _emberComposabilityTools, _object, _object2, _template2, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let ToggleGroupComponent = _exports.default = (_dec = (0, _component2.layout)(_template.default), _dec2 = (0, _component2.classNames)('toggle-group'), _dec3 = (0, _object.computed)('status'), _dec4 = (0, _component2.className)('md-alternative'), _dec5 = (0, _component2.className)('md-large'), _dec6 = (0, _component2.className)('md-underlined'), _dec7 = (0, _component2.className)('md-uppercase'), _dec8 = (0, _component2.className)('md-transparent'), _dec9 = (0, _object2.observes)('selected'), _dec10 = (0, _object.computed)('childWidth', 'paginated', 'itemsPerPage'), _dec11 = (0, _object.computed)('childWidth', 'paginated', 'itemsPerPage', 'selectedPageItem'), _dec12 = (0, _object.computed)('childComponents.[]'), _dec(_class = _dec2(_class = (_class2 = class ToggleGroupComponent extends _component.default.extend(_troveColorMixin.default, _emberComposabilityTools.ParentMixin) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "alternative", _descriptor, this);
      _initializerDefineProperty(this, "large", _descriptor2, this);
      _initializerDefineProperty(this, "underlined", _descriptor3, this);
      _initializerDefineProperty(this, "uppercase", _descriptor4, this);
      _initializerDefineProperty(this, "transparent", _descriptor5, this);
      _defineProperty(this, "selected", void 0);
      _defineProperty(this, "itemsPerPage", 4);
      _defineProperty(this, "status", 'default');
      _defineProperty(this, "paginated", false);
      _defineProperty(this, "label", '');
      _defineProperty(this, "onChange", void 0);
      let selectedPageItem = this.get('paginated') ? this.get('selected') : null;
      selectedPageItem = selectedPageItem - (this.get('itemsPerPage') - 1) >= 0 ? selectedPageItem : this.get('itemsPerPage') - 1;
      this.set('selectedPageItem', selectedPageItem);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs();
      this.selectedObserver();
    }
    get mdStyle() {
      return `md-${this.get('status')}`;
    }
    selectedObserver() {
      if (this.get('selected') > this.get('selectedPageItem')) {
        this.set('selectedPageItem', this.get('selected'));
      }
      if (this.get('selected') < this.get('selectedPageItem') - (this.get('itemsPerPage') - 1)) {
        this.set('selectedPageItem', this.get('selectedPageItem') - 1);
      }
    }
    didInsertElement() {
      super.didInsertElement(...arguments);
      if (this.get('paginated')) {
        // TODO: support toggles of different widths
        let width;
        this.get('childComponents').forEach(child => {
          child = document.querySelector(`#${child.elementId}`);
          width = child.clientWidth;
        });
        this.set('childWidth', width);
      }
    }
    get paginationStyles() {
      return this.get('paginated') ? (0, _template2.htmlSafe)(`
      max-width: ${this.get('childWidth') * this.get('itemsPerPage')}px;
    `) : '';
    }
    get innerContainerStyles() {
      return this.get('paginated') ? (0, _template2.htmlSafe)(`
      left: -${(this.get('selectedPageItem') - (this.get('itemsPerPage') - 1)) * this.get('childWidth')}px;
    `) : '';
    }
    registerChild(childComponent) {
      super.registerChild(...arguments);
      if (childComponent.get('value') === undefined) {
        let length = this.childComponents.get('length');
        childComponent.set('value', length - 1);
      }
    }
    get numberOfChildren() {
      return this.get('childComponents.length');
    }
    prev(selectedPageItem) {
      return this.set('selectedPageItem', selectedPageItem - 1);
    }
    next(selectedPageItem) {
      return this.set('selectedPageItem', selectedPageItem + 1);
    }
    change(selected) {
      if (selected.value > this.get('selectedPageItem')) {
        // this.set('selectedPageItem', selected.value);
      }
      if (this.onChange) {
        this.onChange(selected.get('value'));
      } else {
        this.set('selected', selected.get('value'));
      }
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "mdStyle", [_component2.className, _dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "mdStyle"), _class2.prototype), _descriptor = _applyDecoratedDescriptor(_class2.prototype, "alternative", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "large", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "underlined", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "uppercase", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "transparent", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "selectedObserver", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "selectedObserver"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "paginationStyles", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "paginationStyles"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "innerContainerStyles", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "innerContainerStyles"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "numberOfChildren", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "numberOfChildren"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "prev", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "prev"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "next", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "next"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "change"), _class2.prototype), _class2)) || _class) || _class);
});