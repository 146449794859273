define("@trovedata/sunstone-ui-commons/services/trove-fetch", ["exports", "@ember/service", "fetch", "@ember/object", "@ember/utils"], function (_exports, _service, _fetch, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4; // @ts-ignore
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let TroveFetch = _exports.default = (_dec = (0, _object.computed)(), _class = class TroveFetch extends _service.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "apiGateway", _descriptor, this);
      _initializerDefineProperty(this, "config", _descriptor2, this);
      _initializerDefineProperty(this, "flashMessages", _descriptor3, this);
      _initializerDefineProperty(this, "keycloakSession", _descriptor4, this);
    }
    get host() {
      const {
        config
      } = this;
      const host = config.get('trove.apiGatewayBaseUrl') ? config.get('trove.apiGatewayBaseUrl') : config.get('trove.api.host');
      return (0, _utils.isEmpty)(host) ? '/' : host;
    }
    get headers() {
      return {
        Accept: 'application/hal+json,application/json',
        Authorization: `Bearer ${this.keycloakSession.token}`
      };
    }
    async request(requestInfo, requestInit = {}, isReport = false) {
      requestInit = {
        ...requestInit,
        method: 'GET'
      };
      return this.fetch(requestInfo, requestInit, isReport);
    }
    async post(requestInfo, requestInit = {}, isReport = false) {
      requestInit = {
        ...requestInit,
        method: 'POST'
      };
      return this.fetch(requestInfo, requestInit, isReport);
    }
    async patch(requestInfo, requestInit = {}) {
      requestInit = {
        ...requestInit,
        method: 'PATCH'
      };
      return this.fetch(requestInfo, requestInit);
    }
    async put(requestInfo, requestInit = {}) {
      requestInit = {
        ...requestInit,
        method: 'PUT'
      };
      return this.fetch(requestInfo, requestInit);
    }
    async delete(requestInfo, requestInit = {}) {
      requestInit = {
        ...requestInit,
        method: 'DELETE'
      };
      return this.fetch(requestInfo, requestInit);
    }
    async fetch(requestInfo, requestInit, isReport) {
      requestInit.headers = this.headers;
      if (requestInit.namespace === undefined) {
        requestInit.namespace = this.config.get('trove.api.namespace');
      }
      if (requestInit.accept) {
        requestInit.headers.accept = requestInit.accept;
      }
      if (requestInit.contentType) {
        requestInit.headers['content-type'] = requestInit.contentType;
      }
      if (requestInit.data) {
        requestInit.body = requestInit.contentType === 'application/json' ? JSON.stringify(requestInit.data) : requestInit.data;
        if (requestInit.contentType !== 'application/json') {
          const data = new FormData();
          for (let key in requestInit.data) {
            data.append(key, requestInit.data[key]);
          }
          requestInit.body = data;
        }
      }
      let response = await (0, _fetch.default)(this.buildURL(requestInfo, requestInit), requestInit);
      response = this.handleResponse(response.status, response);
      if (isReport) return await response.blob();
      try {
        const data = await response.json();
        return data;
      } catch (error) {
        const data = await response.text();
        return data;
      }
    }
    buildURL(requestInfo, requestInit) {
      const requestInfoSlashParts = `${requestInfo}`.split('/');
      const host = requestInit.host ? requestInit.host : this.host;
      const hostSlashParts = `${host}`.split('/');
      let url = host;
      if (hostSlashParts.length && hostSlashParts[hostSlashParts.length - 1] !== '') {
        url = `${url}/`;
      }
      if (requestInit.serviceId) {
        const serviceInfo = this.apiGateway.getServiceById(requestInit.serviceId);
        if (serviceInfo) {
          if (serviceInfo.baseRoute.startsWith('/')) {
            // The service is behind the api-gateway
            const ns = serviceInfo.baseRoute.substring(1, serviceInfo.baseRoute.length - 1);
            url = `${url}${ns}/`;
          }
        } else if (requestInit.namespace) {
          url = `${url}${requestInit.namespace}/`;
        }
      } else if (requestInit.namespace) {
        url = `${url}${requestInit.namespace}/`;
      }
      if (requestInfoSlashParts.length && requestInfoSlashParts[0] === '') {
        requestInfoSlashParts.shift();
      }
      url = `${url}${requestInfoSlashParts.join('/')}`;
      return url;
    }
    handleResponse(status, payload) {
      if (`${status}`[0] === '5' || `${status}`[0] === '4') {
        throw payload;
      }
      return payload;
    }
    resolvePromiseWithToast(promise, options = {}) {
      return this.resolve(promise, options);
    }
    async resolve(promise, options = {}) {
      try {
        const response = await promise;
        if (options.successMessage) {
          this.flashMessages.success(options.successMessage);
        }
        if (options.successCallBack) {
          options.successCallBack(response);
        }
        return response;
      } catch (error) {
        console.warn('error captured', error);
        if (options.errorMessage) {
          this.flashMessages.danger(options.errorMessage);
        }
        if (options.errorCallBack) {
          options.errorCallBack(error);
        }
        return 'errorDefault' in options ? options.errorDefault : undefined;
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "apiGateway", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "config", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "keycloakSession", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "host", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "host"), _class.prototype), _class); // DO NOT DELETE: this is how TypeScript knows how to look up your services.
});