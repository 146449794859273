define("@trovedata/trove-scenario-planner/scenario-planner/plan/route", ["exports", "@ember/object", "@ember/routing/route"], function (_exports, _object, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let PlanRoute = _exports.default = (_class = class PlanRoute extends _route.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "queryParams", {
        planId: {
          refreshModel: true
        }
      });
      _defineProperty(this, "controller", void 0);
    }
    model({
      groupingId
    }) {
      // @ts-ignore
      return this.store.findRecord('grouping', groupingId);
    }
    setupController(controller, model, transition) {
      super.setupController(controller, model, transition);
      // @ts-ignore
      controller.getHasBudgetTask.perform();
      // @ts-ignore
      controller.getPlansTask.perform();
      // @ts-ignore
      controller.getGroupingStatistics.perform();
    }
    updatePlans({
      plan,
      startPlan
    }) {
      // @ts-ignore
      this.controller.getPlansTask.perform();
      this.controller.selectPlan(plan, false, startPlan);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "updatePlans", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updatePlans"), _class.prototype), _class);
});