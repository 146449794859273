define("@trovedata/sunstone-ui-commons/services/sunstone-socket", ["exports", "rsvp", "@ember/service", "@ember/utils", "@ember/object"], function (_exports, _rsvp, _service, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  /* eslint-disable ember/no-get */
  /* global SockJS, webstomp */
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let SunstoneSocketService = _exports.default = (_dec = (0, _object.computed)('config.trove.api.host', '_host'), _class = class SunstoneSocketService extends _service.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "config", _descriptor, this);
      _defineProperty(this, "_host", void 0);
    }
    get host() {
      let apiHost = this._host || (0, _object.get)(this, 'config.trove.api.host');
      return !(0, _utils.isEmpty)(apiHost) ? apiHost : window.location.origin;
    }
    set host(host) {
      this.set('_host', host);
    }
    createSocket(url, debug) {
      let stomp = this._getStompConnection(url, debug);
      return new _rsvp.Promise((resolve, reject) => {
        stomp.connect({}, () => {
          resolve(stomp);
        }, error => {
          reject(error);
        });
      });
    }
    _buildUrl(url) {
      const completeUrlRegex = /^(http|https)/;
      let urlComplete = url.match(completeUrlRegex);
      if (!urlComplete) {
        url = `${this.host}/${url}`;
      }
      url = url.replace(/([^:]\/)\/+/g, '$1');
      let {
        access_token
      } = this.get('session.data.authenticated') ? this.get('session.data.authenticated') : {};
      if (access_token) {
        url = `${url}?access_token=${access_token}`;
      }
      return url;
    }
    _getStompConnection(url, debug) {
      url = this._buildUrl(url);
      let socket = new SockJS(url);
      let stomp = webstomp.over(socket);
      if (!debug) {
        stomp.debug = () => {};
      }
      return stomp;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "config", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "host", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "host"), _class.prototype), _class);
});