define("@trovedata/trove-scenario-planner/scenario-planner/groupings/show/plans/controller", ["exports", "@ember/controller", "@ember/object/computed", "@ember/service", "@trovedata/sunstone-ui-commons/infinity/global-infinity-model", "ember-concurrency-decorators"], function (_exports, _controller, _computed, _service, _globalInfinityModel, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let PlansController = _exports.default = (_dec = (0, _controller.inject)('scenario-planner'), _dec2 = (0, _controller.inject)('scenario-planner/groupings/show'), _dec3 = (0, _computed.reads)('groupingsShowController.grouping'), _dec4 = (0, _computed.reads)('applicationController.year'), _dec5 = (0, _emberConcurrencyDecorators.lastValue)('getRegionalPlans'), _class = class PlansController extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "customization", _descriptor, this);
      _initializerDefineProperty(this, "infinity", _descriptor2, this);
      _initializerDefineProperty(this, "troveFetch", _descriptor3, this);
      _initializerDefineProperty(this, "applicationController", _descriptor4, this);
      _initializerDefineProperty(this, "groupingsShowController", _descriptor5, this);
      _initializerDefineProperty(this, "grouping", _descriptor6, this);
      _initializerDefineProperty(this, "year", _descriptor7, this);
      _initializerDefineProperty(this, "plans", _descriptor8, this);
    }
    get tableHeaders() {
      return [{
        name: 'Name',
        sortKey: 'name'
      }, {
        name: 'Version',
        sortKey: 'publishedVersion'
      }, {
        name: 'Reports'
      }, {
        align: 'center',
        name: 'Status',
        sortKey: 'status'
      }, {
        name: 'Created By',
        sortKey: 'createdBy'
      }, {
        name: 'Created Date',
        sortKey: 'createdDate'
      }, {
        name: 'Comments'
      }, {
        name: 'Visibility',
        sortKey: 'isHidden'
      }];
    }
    *getRegionalPlans() {
      const {
        grouping,
        infinity,
        troveFetch,
        year
      } = this;
      const errorMessage = 'Failed to get regional plans';
      return yield troveFetch.resolve(infinity.model('plan', {
        endpoint: 'findAllByRegionNameAndYearAndNameAndCreatedByAndIsHidden',
        params: {
          regionName: grouping.displayName,
          year
        }
      }, _globalInfinityModel.default.extend()), {
        errorMessage
      });
    }
    *updatePlan(plan, bool) {
      plan.isHidden = !bool;
      const promise = plan.save();
      yield this.troveFetch.resolve(promise, {
        errorCallBack: () => plan.rollbackAttributes(),
        errorMessage: 'Failed to update visibility',
        successMessage: 'Successfully updated visibility!'
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "customization", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "infinity", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "troveFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "applicationController", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "groupingsShowController", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "grouping", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "year", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "plans", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getRegionalPlans", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getRegionalPlans"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatePlan", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updatePlan"), _class.prototype), _class); // DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
});