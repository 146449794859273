define("@trovedata/sunstone-ui-commons/components/trove-button/component", ["exports", "@ember/runloop", "@ember/object", "@trovedata/sunstone-ui-commons/components/trove-button/template", "ember-paper/components/paper-button", "@ember/object/computed", "@ember-decorators/object", "@trovedata/sunstone-ui-commons/mixins/trove-color-mixin", "@ember-decorators/component", "@trovedata/sunstone-ui-commons/utils/component-utils"], function (_exports, _runloop, _object, _template, _paperButton, _computed, _object2, _troveColorMixin, _component, _componentUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let TroveButtonComponent = _exports.default = (_dec = (0, _component.layout)(_template.default), _dec2 = (0, _component.attribute)('disabled'), _dec3 = (0, _computed.or)('isPending', 'disabled', 'loading'), _dec4 = (0, _component.className)('md-raised'), _dec5 = (0, _component.className)('md-icon-button'), _dec6 = (0, _component.className)('md-fab'), _dec7 = (0, _component.className)('md-mini'), _dec8 = (0, _component.className)('md-alert'), _dec9 = (0, _component.className)('md-round'), _dec10 = (0, _component.className)('md-auto-width'), _dec11 = (0, _component.className)('md-inverted'), _dec12 = (0, _object.computed)('alternative'), _dec13 = (0, _object.computed)('info'), _dec14 = (0, _component.attribute)('style'), _dec15 = (0, _object.computed)('opacity'), _dec16 = (0, _component.className)('md-uppercase'), _dec17 = (0, _object.computed)('size'), _dec18 = (0, _object2.observes)('promise'), _dec(_class = (_class2 = class TroveButtonComponent extends _paperButton.default.extend(_troveColorMixin.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "type", _descriptor, this);
      _initializerDefineProperty(this, "download", _descriptor2, this);
      _initializerDefineProperty(this, "isDisabled", _descriptor3, this);
      _initializerDefineProperty(this, "raised", _descriptor4, this);
      _initializerDefineProperty(this, "iconButton", _descriptor5, this);
      _initializerDefineProperty(this, "fab", _descriptor6, this);
      _initializerDefineProperty(this, "mini", _descriptor7, this);
      _initializerDefineProperty(this, "alert", _descriptor8, this);
      _initializerDefineProperty(this, "round", _descriptor9, this);
      _initializerDefineProperty(this, "autoWidth", _descriptor10, this);
      _initializerDefineProperty(this, "inverted", _descriptor11, this);
      _defineProperty(this, "alternative", false);
      _initializerDefineProperty(this, "uppercase", _descriptor12, this);
      _defineProperty(this, "tooltip", null);
      _defineProperty(this, "text", 'Save');
      _defineProperty(this, "side", 'bottom');
      _defineProperty(this, "isPending", false);
      _defineProperty(this, "disabled", false);
      _defineProperty(this, "promise", void 0);
      _defineProperty(this, "size", void 0);
      _defineProperty(this, "opacity", 1);
      _defineProperty(this, "info", void 0);
      _defineProperty(this, "onClick", void 0);
    }
    get alternativeShade() {
      return (0, _componentUtils.calcColorVar)('md-alternative', this.alternative);
    }
    get infoShade() {
      return (0, _componentUtils.calcColorVar)('md-info', this.info);
    }
    get componentStyles() {
      return (0, _componentUtils.calcStyles)({
        opacity: this.opacity
      });
    }
    get sizeClass() {
      return this.size ? `md-${this.size}` : undefined;
    }
    handlePromise() {
      let promise = this.promise;
      if (!promise || typeof promise.finally !== 'function') {
        return;
      }
      (0, _runloop.run)(() => {
        (0, _object.set)(this, 'isPending', true);
      });
      promise.finally(() => {
        (0, _runloop.run)(() => {
          if ((0, _object.get)(this, 'isPending')) {
            //@ts-ignore
            if (!this.isDestroyed) {
              (0, _object.set)(this, 'isPending', false);
            }
          }
        });
      });
    }
    click(event) {
      let action = (0, _object.get)(this, 'onClick');
      if (typeof action === 'function') {
        event.stopPropagation();
        (0, _object.set)(this, 'promise', action());
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "type", [_component.attribute], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'button';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "download", [_component.attribute], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "isDisabled", [_dec2, _dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "raised", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "iconButton", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "fab", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "mini", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "alert", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "round", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "autoWidth", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "inverted", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "alternativeShade", [_component.className, _dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "alternativeShade"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "infoShade", [_component.className, _dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "infoShade"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "componentStyles", [_dec14, _dec15], Object.getOwnPropertyDescriptor(_class2.prototype, "componentStyles"), _class2.prototype), _descriptor12 = _applyDecoratedDescriptor(_class2.prototype, "uppercase", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "sizeClass", [_component.className, _dec17], Object.getOwnPropertyDescriptor(_class2.prototype, "sizeClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handlePromise", [_dec18], Object.getOwnPropertyDescriptor(_class2.prototype, "handlePromise"), _class2.prototype), _class2)) || _class);
});