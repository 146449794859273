define("@trovedata/trove-scenario-planner/models/line", ["exports", "@ember/object", "@ember/service", "@trovedata/sunstone-ui-commons/models/service-point-group", "@trovedata/sunstone-ui-commons/utils/array-utils", "ember-concurrency-decorators", "ember-data"], function (_exports, _object, _service, _servicePointGroup, _arrayUtils, _emberConcurrencyDecorators, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const {
    attr
  } = _emberData.default;
  let Line = _exports.default = (_dec = (0, _object.computed)('parameters.[]'), _dec2 = (0, _object.computed)('parameters.[]'), _dec3 = attr('string'), _dec4 = (0, _emberConcurrencyDecorators.lastValue)('getLineRiskData'), _dec5 = (0, _object.computed)('sessionResults.[]'), _class = class Line extends _servicePointGroup.default {
    constructor(...args) {
      super(...args);
      // @ts-ignore
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "troveFetch", _descriptor2, this);
      _initializerDefineProperty(this, "config", _descriptor3, this);
      _initializerDefineProperty(this, "name", _descriptor4, this);
      _initializerDefineProperty(this, "sessionResults", _descriptor5, this);
    }
    get lengthInMetersParameter() {
      return this.parameters.findBy('name', 'length_in_meters');
    }
    get priorityParameter() {
      return this.parameters.findBy('name', 'priority');
    }
    get spanRiskResultsByEntityId() {
      return (0, _arrayUtils.buildHash)(this.sessionResults ? this.sessionResults : [], 'entityId', 'results');
    }
    *getLineRiskData() {
      return yield this.troveFetch.resolvePromiseWithToast(this.store.query('model-session-result', {
        endpoint: 'findByModelSessionIdAndLevelAndLine',
        params: {
          aggregationLevel: 'Span',
          line: this.name,
          modelSessionId: this.config.get('trove.modelSessionId')
        }
      }));
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "troveFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "config", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "lengthInMetersParameter", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "lengthInMetersParameter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "priorityParameter", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "priorityParameter"), _class.prototype), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sessionResults", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "spanRiskResultsByEntityId", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "spanRiskResultsByEntityId"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getLineRiskData", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getLineRiskData"), _class.prototype), _class); // DO NOT DELETE: this is how TypeScript knows how to look up your models.
});