define("@trovedata/sunstone-ui-commons/components/paper-chips/component", ["exports", "ember-paper/components/paper-chips/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let PaperChips = _exports.default = (_class = class PaperChips extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "onChange", void 0);
      _defineProperty(this, "openDefault", void 0);
    }
    handleSearchTextChange(value) {
      let _super = super.actions.handleSearchTextChange.bind(this);
      _super(...arguments);
      if (this.onChange) {
        this.onChange(value);
      }
    }
    handleAutocompleteOpen(_select, e) {
      if (e && e.type === 'focus' && !this.openDefault) {
        return false;
      }
      return;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "handleSearchTextChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSearchTextChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleAutocompleteOpen", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleAutocompleteOpen"), _class.prototype), _class);
});