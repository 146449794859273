define("@trovedata/trove-scenario-planner/models/grouping", ["exports", "@ember/object/computed", "@ember/service", "@trovedata/sunstone-ui-commons/utils/string-utils", "ember-concurrency-decorators", "ember-data/attr", "ember-data/model", "ember-data/relationships"], function (_exports, _computed, _service, _stringUtils, _emberConcurrencyDecorators, _attr, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let Grouping = _exports.default = (_dec = (0, _attr.default)('boolean'), _dec2 = (0, _attr.default)('string'), _dec3 = (0, _attr.default)('boolean'), _dec4 = (0, _attr.default)('json'), _dec5 = (0, _attr.default)('string'), _dec6 = (0, _relationships.belongsTo)('grouping'), _dec7 = (0, _computed.alias)('parentId'), _class = class Grouping extends _model.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "config", _descriptor, this);
      _initializerDefineProperty(this, "infinity", _descriptor2, this);
      // @ts-ignore
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "troveFetch", _descriptor4, this);
      _initializerDefineProperty(this, "active", _descriptor5, this);
      _initializerDefineProperty(this, "displayName", _descriptor6, this);
      _initializerDefineProperty(this, "existing", _descriptor7, this);
      // @ts-ignore
      _initializerDefineProperty(this, "geom", _descriptor8, this);
      _initializerDefineProperty(this, "name", _descriptor9, this);
      _initializerDefineProperty(this, "parentId", _descriptor10, this);
      _initializerDefineProperty(this, "parentGrouping", _descriptor11, this);
      _defineProperty(this, "plans", void 0);
    }
    get formattedDisplayName() {
      return (0, _stringUtils.titleize)(`${this.displayName}`);
    }
    get lowerDisplayName() {
      return this.displayName?.toLowerCase();
    }
    *getPlansForGroupingTask(year, callback) {
      const plans = yield this.troveFetch.resolve(this.infinity.model('plan', {
        endpoint: 'findAllByRegionNameAndYearAndNameAndCreatedByAndIsHidden',
        params: {
          isHidden: false,
          regionName: this.displayName,
          year
        }
      }));
      this.set('plans', plans);
      if (callback && plans) callback(plans.toArray());
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "config", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "infinity", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "troveFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "active", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "displayName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "existing", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "geom", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "parentId", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "parentGrouping", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getPlansForGroupingTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getPlansForGroupingTask"), _class.prototype), _class); // DO NOT DELETE: this is how TypeScript knows how to look up your models.
});