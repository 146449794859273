define("ember-keyboard/deprecated/modifiers/keyboard-shortcut", ["exports", "ember-modifier", "@ember/service", "@ember/debug"], function (_exports, _emberModifier, _service, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let Klass;
  if (true) {
    var _class, _descriptor;
    /* This is an element modifier to trigger a click on the element when
     * the specified key is pressed. In the following example, we trigger a
     * click on the button when the B key is pressed:
     *
     * <button {{keyboard-shortcut 'KeyB'}}>Press me or press "B"</button>
     */
    Klass = (_class = class KeyboardShortcutModifier extends _emberModifier.default {
      constructor(...args) {
        super(...args);
        _initializerDefineProperty(this, "keyboard", _descriptor, this);
        _defineProperty(this, "keyboardActivated", true);
        _defineProperty(this, "keyboardPriority", 0);
        _defineProperty(this, "keyboardFirstResponder", false);
        _defineProperty(this, "keyboardEventType", 'keypress');
      }
      didReceiveArguments() {
        this.key = this.args.positional[0];
        if ('keyboardActivated' in this.args.named) {
          this.keyboardActivated = this.args.named.keyboardActivated;
        }
        if ('keyboardPriority' in this.args.named) {
          this.keyboardPriority = this.args.named.keyboardPriority;
        }
        if ('keyboardFirstResponder' in this.args.named) {
          this.keyboardFirstResponder = this.args.named.keyboardFirstResponder;
        }
        if ('keyboardEventType' in this.args.named) {
          this.keyboardEventType = this.args.named.keyboardEventType;
        }
      }
      didInstall() {
        (false && !(false) && (0, _debug.deprecate)('The `keyboard-shortcut` modifier of ember-keyboard is deprecated. Please use the `on-key` modifier with no action instead.', false, {
          id: 'ember-keyboard.keyboard-shortcut',
          for: 'ember-keyboard',
          since: '6.0.2',
          until: '7.0.0',
          url: 'https://adopted-ember-addons.github.io/ember-keyboard/deprecations#keyboard-shortcut'
        }));
        try {
          this.keyboard.register(this);
        } catch (e) {
          if (false) {
            console.warn('ember-keyboard\'s modifiers are only supported in Ember 3.8+');
          }
          throw e;
        }
      }
      willRemove() {
        this.keyboard.unregister(this);
      }
      has(triggerName) {
        return triggerName === this.keyboardEventName && this.keyboardActivated;
      }
      trigger(listenerName) {
        if (this.keyboardActivated && listenerName === this.keyboardEventName) {
          this.element.click();
        }
      }
      get keyboardEventName() {
        let {
          key,
          keyboardEventType
        } = this;
        return `${keyboardEventType}:${key}`;
      }
    }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "keyboard", [_service.inject], {
      configurable: true,
      enumerable: true,
      writable: true,
      initializer: null
    }), _class);
  } else {
    Klass = class OnKeyboardModifier extends _emberModifier.default {
      didInstall() {
        throw new Error('ember-keyboard only supports the keyboard-shortcut element modifier in Ember 3.8 and higher.');
      }
    };
  }
  var _default = _exports.default = Klass;
});