define("@trovedata/sunstone-ui-commons/models/model-instance-parameter", ["exports", "ember-data", "@ember/service", "@ember/object/computed", "@ember/object", "ember-data/model"], function (_exports, _emberData, _service, _computed, _object, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const {
    attr,
    belongsTo
  } = _emberData.default;
  let ModelInstanceParameter = _exports.default = (_dec = attr({
    dynamicTransform: 'parameter.dataType'
  }), _dec2 = attr('number', {
    defaultValue: 1
  }), _dec3 = belongsTo('profile', {
    id: true
  }), _dec4 = belongsTo('model-instance', {
    async: true
  }), _dec5 = belongsTo('model-parameter'), _dec6 = (0, _computed.alias)('modelInstance'), _dec7 = (0, _computed.alias)('modelParameter'), _dec8 = (0, _computed.alias)('parameter.dataType'), _dec9 = (0, _computed.bool)('parameter.options'), _dec10 = (0, _computed.oneWay)('parameter.displayOrder'), _dec11 = (0, _computed.or)('parameter.displayName', 'parameter.name'), _dec12 = (0, _computed.alias)('parameter.required'), _dec13 = (0, _object.computed)('hasOptions'), _class = class ModelInstanceParameter extends _model.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "troveFetch", _descriptor, this);
      //@ts-ignore
      _initializerDefineProperty(this, "value", _descriptor2, this);
      _initializerDefineProperty(this, "orgId", _descriptor3, this);
      //@ts-ignore
      _initializerDefineProperty(this, "profile", _descriptor4, this);
      _initializerDefineProperty(this, "modelInstance", _descriptor5, this);
      _initializerDefineProperty(this, "modelParameter", _descriptor6, this);
      _initializerDefineProperty(this, "instance", _descriptor7, this);
      _initializerDefineProperty(this, "parameter", _descriptor8, this);
      _initializerDefineProperty(this, "dataType", _descriptor9, this);
      _initializerDefineProperty(this, "hasOptions", _descriptor10, this);
      _initializerDefineProperty(this, "displayOrder", _descriptor11, this);
      _initializerDefineProperty(this, "name", _descriptor12, this);
      _initializerDefineProperty(this, "required", _descriptor13, this);
      _defineProperty(this, "validations", {
        values: {
          presence: true
        },
        instance: true,
        parameter: true
      });
      _defineProperty(this, "providedOptions", void 0);
    }
    init() {
      super.init();

      // @ts-ignore
      if (this.isNew && this.dataType === 'BOOLEAN' && this.required) {
        this.set('value', false);
      }

      // @ts-ignore
      if (this.isNew && this.dataType === 'ARRAY' && this.required) {
        this.set('value', []);
      }
    }
    get options() {
      return this.providedOptions || [];
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "troveFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "orgId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "profile", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "modelInstance", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "modelParameter", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "instance", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "parameter", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "dataType", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "hasOptions", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "displayOrder", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "required", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "options", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "options"), _class.prototype), _class); // DO NOT DELETE: this is how TypeScript knows how to look up your models.
});