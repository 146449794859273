define("@trovedata/sunstone-ui-commons/components/paper-async-button/component", ["exports", "@ember/runloop", "@ember/object", "@ember/application/deprecations", "@trovedata/sunstone-ui-commons/components/paper-async-button/template", "ember-paper/components/paper-button", "@ember/object/computed", "@ember-decorators/component", "@ember-decorators/object"], function (_exports, _runloop, _object, _deprecations, _template, _paperButton, _computed, _component, _object2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2; // @ts-ignore
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let deprecateService = function () {
    (false && !(false) && (0, _deprecations.deprecate)('[paper-async-button] The paper-async-button will no longer be supported. Please update your app to use `component:trove-button`', false, {
      id: 'sunstone-ui-commons.paper-async-button',
      until: 'sunstone-ui-commons-3.0'
    }));
  };
  let PaperAsyncButtonComponent = _exports.default = (_dec = (0, _component.layout)(_template.default), _dec2 = (0, _component.classNames)('async-button'), _dec3 = (0, _component.attribute)('disabled'), _dec4 = (0, _computed.or)('isPending', 'disabled'), _dec5 = (0, _object2.observes)('promise'), _dec(_class = _dec2(_class = (_class2 = class PaperAsyncButtonComponent extends _paperButton.default.reopenClass({
    positionalParams: ['action']
  }) {
    handlePromise() {
      let promise = this.promise;
      if (!promise || typeof promise.finally !== 'function') {
        return;
      }
      (0, _runloop.run)(() => {
        (0, _object.set)(this, 'isPending', true);
      });
      promise.finally(() => {
        (0, _runloop.run)(() => {
          (0, _object.set)(this, 'isPending', false);
        });
      });
    }
    click(event) {
      let action = (0, _object.get)(this, 'action');
      if (typeof action === 'function') {
        event.stopPropagation();
        (0, _object.set)(this, 'promise', action());
      }
    }
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "type", _descriptor, this);
      _defineProperty(this, "text", 'Save');
      _defineProperty(this, "isPending", false);
      _defineProperty(this, "disabled", false);
      _initializerDefineProperty(this, "isDisabled", _descriptor2, this);
      _defineProperty(this, "promise", void 0);
      _defineProperty(this, "action", void 0);
      deprecateService();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "type", [_component.attribute], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'button';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "isDisabled", [_dec3, _dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "handlePromise", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "handlePromise"), _class2.prototype), _class2)) || _class) || _class);
});