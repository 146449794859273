define("@trovedata/trove-scenario-planner/models/budget-entry", ["exports", "@ember/object", "@ember/service"], function (_exports, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let BudgetEntry = _exports.default = (_dec = (0, _object.computed)('grouping'), _dec2 = (0, _object.computed)('totalSpend', 'expectedSpend', 'budgetAmount'), _dec3 = (0, _object.computed)('totalSpend', 'expectedSpend'), _dec4 = (0, _object.computed)('expectedSpend'), _dec5 = (0, _object.computed)('totalSpend'), _dec6 = (0, _object.computed)('budgetAmount'), _class = class BudgetEntry extends _object.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _defineProperty(this, "name", void 0);
      _defineProperty(this, "budgetAmount", void 0);
      _defineProperty(this, "grouping", void 0);
      _defineProperty(this, "totalSpend", void 0);
      _defineProperty(this, "expectedSpend", void 0);
      _defineProperty(this, "regionId", void 0);
      _defineProperty(this, "green", 'linear-gradient(270deg, RGB(var(--accent-700)) 0%, #35987A 100%);');
      _defineProperty(this, "red", 'linear-gradient(270deg, RGB(var(--warn-500)) 0%, #F50057 100%);');
    }
    get lowerGrouping() {
      return this.grouping?.toLowerCase();
    }
    // private readonly blue = 'RGB(var(--info-500));';

    get barColor() {
      if (this.totalSpend > this.budgetAmount || this.totalSpend > this.expectedSpend) {
        return this.red;
      } else if (this.totalSpend <= this.expectedSpend) {
        return this.green;
      }
      return;
    }
    get budgetEqualsExpected() {
      return this.budgetAmount === this.expectedSpend;
    }
    get expectedDescription() {
      return `$${this.intl.formatNumber(this.expectedSpend)}`;
    }
    get description() {
      return `$${this.intl.formatNumber(this.totalSpend)}`;
    }
    get description2() {
      if (this.budgetAmount) {
        return `$${this.formatAmount(this.budgetAmount)} budget`;
      }
      return undefined;
    }

    /*
      TODO:  This mimics the behavior of the Trove number helper function.  It would be nice if we
      could utilize the helper here instead of having copied functionality.
    */
    formatAmount(value, options = {
      precision: 2,
      shorten: true
    }) {
      if (!value) {
        return undefined;
      }
      const {
        precision,
        shorten
      } = options;
      const abbrs = ['b', 'm', 'k'];
      const thresholds = [1000000000, 1000000, 1000];
      const divisors = [1000000000, 1000000, 1000];
      let selectedAbbr = '';
      if (shorten) {
        for (let index = 0; index < thresholds.length; index++) {
          if (value >= thresholds[index]) {
            value = value / divisors[index];
            selectedAbbr = abbrs[index];
            break;
          }
        }
      }
      value = this.intl.formatNumber(value, {
        maximumFractionDigits: precision || 10
      });
      return `${value}${selectedAbbr}`;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "lowerGrouping", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "lowerGrouping"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "barColor", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "barColor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "budgetEqualsExpected", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "budgetEqualsExpected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "expectedDescription", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "expectedDescription"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "description", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "description"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "description2", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "description2"), _class.prototype), _class);
});