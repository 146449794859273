define("@trovedata/trove-scenario-planner/services/customization", ["exports", "@ember/service", "@glimmer/tracking", "ember-concurrency-decorators", "@trovedata/trove-scenario-planner/utils/service-utils"], function (_exports, _service, _tracking, _emberConcurrencyDecorators, _serviceUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let Customization = _exports.default = (_dec = (0, _emberConcurrencyDecorators.lastValue)('getBaseTypes'), _dec2 = (0, _emberConcurrencyDecorators.lastValue)('getDefaultSettings'), _dec3 = (0, _emberConcurrencyDecorators.lastValue)('getExcludedSettings'), _dec4 = (0, _emberConcurrencyDecorators.lastValue)('getAssetTypes'), _dec5 = (0, _emberConcurrencyDecorators.lastValue)('getAssetComponentConfigs'), _dec6 = (0, _emberConcurrencyDecorators.lastValue)('getGroupingComponentConfigs'), _class = class Customization extends _service.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "config", _descriptor2, this);
      _initializerDefineProperty(this, "troveFetch", _descriptor3, this);
      /**
       * Grouping Dashboard Grouping Card
       * Sorted Component List
       * Below is default for Duke
       */
      _defineProperty(this, "groupingCardComponents", ['grouping-stats-summary', 'budget-bars', 'risk-chart']);
      /**
       * Plan Dashboard Left Panel Map
       * Expand/Contract Map
       * Below is default for Duke
       */
      _defineProperty(this, "isMapExpanded", false);
      /**
       * Plan Dashboard Left Panel
       * Sorted Component List
       * Below is default for Duke
       */
      _defineProperty(this, "leftPanelComponents", ['grouping-stats', 'polygon-stats', 'risk-chart', 'financial-info']);
      /**
       * Modify Dashboard Right Panel
       * Sorted Component List
       * Below is default for Duke
       */
      _defineProperty(this, "rightPanelComponents", ['risk-cost-stats']);
      /**
       * Plan Dashboard View Explorer
       * Default Circuit
       * Below is default for Duke
       */
      _defineProperty(this, "show", 'in-plan');
      /**
       * Plan Dashboard Left Panel
       * Show/Hide Map
       * Below is default for Duke
       */
      _defineProperty(this, "showSummaryCard", true);
      _initializerDefineProperty(this, "threatCategories", _descriptor4, this);
      _initializerDefineProperty(this, "baseTypes", _descriptor5, this);
      _initializerDefineProperty(this, "defaultSettings", _descriptor6, this);
      _initializerDefineProperty(this, "excludedSettings", _descriptor7, this);
      _initializerDefineProperty(this, "assetTypes", _descriptor8, this);
      _initializerDefineProperty(this, "assetComponentConfigs", _descriptor9, this);
      _initializerDefineProperty(this, "groupingComponentConfigs", _descriptor10, this);
    }
    getDisplayNameForAggType(aggType) {
      const aggName = aggType === 'segment-agg' ? 'LINE_SEGMENT' : aggType.split('-')[0].toUpperCase();
      const type = this.assetTypes?.find(a => a.name === aggName);
      return type ? type.displayName : '';
    }
    getAssetTypeDisplayForAgg(agg) {
      return this.assetTypes?.find(a => a.name === agg.assetType)?.displayName;
    }
    get baseGrouping() {
      return this.baseTypes?.find(b => b.base);
    }
    get nonBaseGrouping() {
      return this.baseTypes?.find(b => !b.base);
    }
    *loadConfigurations() {
      const promises = [];
      // @ts-ignore
      promises.push(this.getAssetTypes.perform());
      // @ts-ignore
      promises.push(this.getAssetComponentConfigs.perform());
      // @ts-ignore
      promises.push(this.getGroupingComponentConfigs.perform());
      // @ts-ignore
      promises.push(this.getDefaultSettings.perform());
      // @ts-ignore
      promises.push(this.getExcludedSettings.perform());
      // @ts-ignore
      promises.push(this.getBaseTypes.perform());
      // @ts-ignore
      promises.push(this.getThreatCategories.perform());
      yield Promise.all(promises);
    }
    *getThreatCategories() {
      const {
        store,
        troveFetch
      } = this;
      const promise = store.findAll('threat-category');
      const errorMessage = 'Could not retrieve threat categories';
      const categories = yield troveFetch.resolve(promise, {
        errorMessage
      });
      if (categories?.length) {
        // @ts-ignore
        this.threatCategories = categories.sortBy('displayOrder');
      }
    }
    *getBaseTypes() {
      const promise = this.store.findAll('base-type');
      const errorMessage = 'Could not retrieve base types';
      return (yield this.troveFetch.resolve(promise, {
        errorMessage
      }))?.toArray();
    }
    *getAssetComponentConfigs() {
      return (yield this.troveFetch.resolve(this.store.query('asset-component-config', {
        endpoint: 'findByIsVisibleTrue'
      }), {
        errorMessage: 'Could not retrieve asset component configuration'
      }))?.toArray();
    }
    *getGroupingComponentConfigs() {
      const promise = this.store.findAll('grouping-component-config');
      const errorMessage = 'Could not retrieve grouping component configuration';
      return (yield this.troveFetch.resolve(promise, {
        errorMessage
      }))?.toArray();
    }
    *getAssetTypes() {
      return (yield this.troveFetch.resolve(this.store.findAll('asset-type'), {
        errorMessage: 'Could not retrieve asset types'
      }))?.toArray();
    }
    *getDefaultSettings() {
      const url = '/settings/defaultSettings?version=2';
      return yield this.troveFetch.resolve(this.troveFetch.request(url, {
        namespace: _serviceUtils.QUERY_SERVICE_NAMESPACE,
        serviceId: _serviceUtils.QUERY_SERVICE_ID
      }), {
        errorMessage: 'Could not retrieve default settings'
      });
    }
    *getExcludedSettings() {
      const url = '/settings/excludedSettings?version=2';
      return yield this.troveFetch.resolve(this.troveFetch.request(url, {
        namespace: _serviceUtils.QUERY_SERVICE_NAMESPACE,
        serviceId: _serviceUtils.QUERY_SERVICE_ID
      }), {
        errorMessage: 'Could not retrieve excluded settings'
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "config", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "troveFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "threatCategories", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "baseTypes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "defaultSettings", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "excludedSettings", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "assetTypes", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "assetComponentConfigs", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "groupingComponentConfigs", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadConfigurations", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "loadConfigurations"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getThreatCategories", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getThreatCategories"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getBaseTypes", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getBaseTypes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getAssetComponentConfigs", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getAssetComponentConfigs"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getGroupingComponentConfigs", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getGroupingComponentConfigs"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getAssetTypes", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getAssetTypes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getDefaultSettings", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getDefaultSettings"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getExcludedSettings", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getExcludedSettings"), _class.prototype), _class); // DO NOT DELETE: this is how TypeScript knows how to look up your services.
});