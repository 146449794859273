define("@trovedata/trove-scenario-planner/models/corridor-line-metric", ["exports", "@glimmer/tracking", "ember-data/attr", "ember-data/model"], function (_exports, _tracking, _attr, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let CorridorLineMetric = _exports.default = (_dec = (0, _attr.default)('string'), _dec2 = (0, _attr.default)('json'), _dec3 = (0, _attr.default)('string'), _dec4 = (0, _attr.default)('number'), _class = class CorridorLineMetric extends _model.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "corridorLineName", _descriptor, this);
      // @ts-ignore
      _initializerDefineProperty(this, "metrics", _descriptor2, this);
      _initializerDefineProperty(this, "region", _descriptor3, this);
      _initializerDefineProperty(this, "year", _descriptor4, this);
      _initializerDefineProperty(this, "isChecked", _descriptor5, this);
      _initializerDefineProperty(this, "isIndeterminate", _descriptor6, this);
      _initializerDefineProperty(this, "isIndeterminateToBeAdded", _descriptor7, this);
      _initializerDefineProperty(this, "isToBeAdded", _descriptor8, this);
      _initializerDefineProperty(this, "showChildren", _descriptor9, this);
      _initializerDefineProperty(this, "showPrimary", _descriptor10, this);
    }
    resetMetadata() {
      this.isChecked = false;
      this.isIndeterminate = false;
      this.isIndeterminateToBeAdded = false;
      this.isToBeAdded = false;
      this.showChildren = false;
      this.showPrimary = false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "corridorLineName", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "metrics", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "region", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "year", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isChecked", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isIndeterminate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isIndeterminateToBeAdded", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isToBeAdded", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "showChildren", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "showPrimary", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _class); // DO NOT DELETE: this is how TypeScript knows how to look up your models.
});