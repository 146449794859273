define("@trovedata/sunstone-ui-commons/models/model-instance-schedule", ["exports", "@ember/object/computed", "@ember/object", "ember-data"], function (_exports, _computed, _object, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.computeCronPart = computeCronPart;
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  function computeCronPart(index, value) {
    let cronParts = this.get('cron').split(' ');
    if (value !== undefined) {
      cronParts[index] = value;
      this.set('cron', cronParts.join(' '));
    }
    return cronParts[index];
  }
  let ModelInstanceSchedule = _exports.default = (_dec = attr('string'), _dec2 = attr('string', {
    defaultValue: '0 0 0/1 1/1 * ? *'
  }), _dec3 = attr('unix-timestamp'), _dec4 = attr('unix-timestamp'), _dec5 = attr('unix-timestamp'), _dec6 = belongsTo('model-instance', {
    async: true
  }), _dec7 = (0, _computed.alias)('modelInstance'), _dec8 = (0, _object.computed)('scheduleStart'), _dec9 = (0, _object.computed)('scheduleEnd'), _dec10 = (0, _object.computed)('cron'), _dec11 = (0, _object.computed)('cron'), _dec12 = (0, _object.computed)('cron'), _dec13 = (0, _object.computed)('cron'), _dec14 = (0, _object.computed)('cron'), _class = class ModelInstanceSchedule extends Model {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "scheduleName", _descriptor, this);
      _initializerDefineProperty(this, "cron", _descriptor2, this);
      // @ts-ignore
      _initializerDefineProperty(this, "scheduleStart", _descriptor3, this);
      // @ts-ignore
      _initializerDefineProperty(this, "scheduleEnd", _descriptor4, this);
      // @ts-ignore
      _initializerDefineProperty(this, "nextRunTime", _descriptor5, this);
      _initializerDefineProperty(this, "modelInstance", _descriptor6, this);
      _initializerDefineProperty(this, "instance", _descriptor7, this);
      _defineProperty(this, "validations", {
        name: {
          presence: true
        },
        cron: {
          presence: true
        },
        instance: true
      });
    }
    get scheduleStartDate() {
      return this.scheduleStart?.toDate();
    }
    get scheduleEndDate() {
      return this.scheduleEnd?.toDate();
    }
    get minute() {
      return computeCronPart.call(this, 1);
    }
    set minute(value) {
      computeCronPart.call(this, 1, value);
    }
    get hour() {
      return computeCronPart.call(this, 2);
    }
    set hour(value) {
      computeCronPart.call(this, 2, value);
    }
    get dayOfMonth() {
      return computeCronPart.call(this, 3);
    }
    set dayOfMonth(value) {
      computeCronPart.call(this, 3, value);
    }
    get month() {
      return computeCronPart.call(this, 4);
    }
    set month(value) {
      computeCronPart.call(this, 4, value);
    }
    get dayOfWeek() {
      return computeCronPart.call(this, 5);
    }
    set dayOfWeek(value) {
      computeCronPart.call(this, 5, value);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "scheduleName", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "cron", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "scheduleStart", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "scheduleEnd", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "nextRunTime", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "modelInstance", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "instance", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "scheduleStartDate", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "scheduleStartDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "scheduleEndDate", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "scheduleEndDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "minute", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "minute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hour", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "hour"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dayOfMonth", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "dayOfMonth"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "month", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "month"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dayOfWeek", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "dayOfWeek"), _class.prototype), _class);
});