define("@trovedata/sunstone-ui-commons/services/app-config", ["exports", "@ember/service", "@glimmer/tracking", "@trovedata/sunstone-ui-commons/utils/configure-maps", "ember-concurrency-decorators"], function (_exports, _service, _tracking, _configureMaps, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AppConfig = _exports.default = (_class = class AppConfig extends _service.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "config", _descriptor, this);
      _initializerDefineProperty(this, "troveFetch", _descriptor2, this);
      _initializerDefineProperty(this, "helpDeskUri", _descriptor3, this);
    }
    loadMaps(source) {
      const {
        config
      } = this;
      const canLoadMap = (!document.querySelector('#maps-cluster') || !document.querySelector('#maps-main')) && !config.get('googleMap.disabled');
      if (!canLoadMap) return;
      const appNamespace = config.get('trove.appNamespace');
      const key = `com.esource.${appNamespace}.google-map-api-key`;
      const gKey = config.get('environment') === 'production' ? source[key] ? source[key] : config.get('googleMap.apiKey') : undefined;
      (0, _configureMaps.default)(config.get('googleMap'), gKey);
    }
    loadHelpUri(source) {
      const appNamespace = this.config.get('trove.appNamespace');
      const key = `com.esource.${appNamespace}.help-desk-uri`;
      this.helpDeskUri = source[key];
    }
    *getAppConfig() {
      const {
        config,
        troveFetch
      } = this;
      const host = config.get('trove.apiGatewayBaseUrl');
      const appNamespace = config.get('trove.appNamespace');
      const url = `/spring-cloud-config-server/${appNamespace}/${config.get('activeProfiles')}`;
      const promise = troveFetch.request(url, {
        host,
        namespace: null
      });
      const configHash = yield troveFetch.resolve(promise);
      const appConfig = configHash?.propertySources?.find(({
        name
      }) => {
        return name.includes(appNamespace);
      });
      const source = appConfig?.source;
      if (!source) return;
      this.loadMaps(source);
      this.loadHelpUri(source);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "config", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "troveFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "helpDeskUri", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getAppConfig", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getAppConfig"), _class.prototype), _class); // DO NOT DELETE: this is how TypeScript knows how to look up your services.
});