define("@trovedata/trove-scenario-planner/models/plan", ["exports", "ember-concurrency-decorators", "ember-data", "ember-data/model", "moment", "@ember/object", "@ember/object/computed", "@ember/service", "@trovedata/sunstone-ui-commons/utils/array-utils"], function (_exports, _emberConcurrencyDecorators, _emberData, _model, _moment, _object, _computed, _service, _arrayUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const {
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  let Plan = _exports.default = (_dec = attr('string'), _dec2 = attr('string'), _dec3 = attr('boolean', {
    defaultValue: false
  }), _dec4 = attr('string'), _dec5 = attr('number'), _dec6 = attr('string'), _dec7 = attr('string'), _dec8 = attr('string'), _dec9 = attr('number', {
    defaultValue: 2
  }), _dec10 = attr('string'), _dec11 = attr('number'), _dec12 = attr('number'), _dec13 = belongsTo('plan', {
    id: true,
    inverse: null
  }), _dec14 = hasMany('plan-setting', {
    async: true
  }), _dec15 = hasMany('plan-budget-entry', {
    async: true,
    serialize: false
  }), _dec16 = hasMany('report', {
    async: true,
    serialize: false
  }), _dec17 = (0, _emberConcurrencyDecorators.lastValue)('getRegionTask'), _dec18 = (0, _computed.equal)('status', 'Archived'), _dec19 = (0, _computed.equal)('status', 'Draft'), _dec20 = (0, _computed.equal)('status', 'Published'), _dec21 = (0, _computed.equal)('status', 'Simulation'), _dec22 = (0, _computed.not)('isHidden'), _dec23 = (0, _object.computed)('planSettings.[]'), _dec24 = (0, _object.computed)('planSettings.[]'), _dec25 = (0, _object.computed)('status'), _dec26 = (0, _object.computed)('budgetEntries', 'budgetEntries.[]'), _dec27 = (0, _object.computed)('budgetEntries', 'budgetEntries.[]'), _dec28 = (0, _object.computed)('budgetEntries.[]'), _class = class Plan extends _model.default {
    constructor(...args) {
      super(...args);
      // @ts-ignore
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "troveFetch", _descriptor2, this);
      _initializerDefineProperty(this, "createdBy", _descriptor3, this);
      _initializerDefineProperty(this, "createdDate", _descriptor4, this);
      _initializerDefineProperty(this, "isHidden", _descriptor5, this);
      _initializerDefineProperty(this, "lastModifiedDate", _descriptor6, this);
      _initializerDefineProperty(this, "lidarYear", _descriptor7, this);
      _initializerDefineProperty(this, "name", _descriptor8, this);
      _initializerDefineProperty(this, "publishedDate", _descriptor9, this);
      _initializerDefineProperty(this, "regionName", _descriptor10, this);
      _initializerDefineProperty(this, "settingsVersion", _descriptor11, this);
      _initializerDefineProperty(this, "status", _descriptor12, this);
      _initializerDefineProperty(this, "year", _descriptor13, this);
      _initializerDefineProperty(this, "publishedVersion", _descriptor14, this);
      // @ts-ignore
      _initializerDefineProperty(this, "publishedPlanId", _descriptor15, this);
      _initializerDefineProperty(this, "planSettings", _descriptor16, this);
      // @ts-ignore
      _initializerDefineProperty(this, "budgetEntries", _descriptor17, this);
      // @ts-ignore
      _initializerDefineProperty(this, "reports", _descriptor18, this);
      _initializerDefineProperty(this, "region", _descriptor19, this);
      _initializerDefineProperty(this, "isArchived", _descriptor20, this);
      _initializerDefineProperty(this, "isDraft", _descriptor21, this);
      _initializerDefineProperty(this, "isPublished", _descriptor22, this);
      _initializerDefineProperty(this, "isSimulation", _descriptor23, this);
      _initializerDefineProperty(this, "isVisible", _descriptor24, this);
    }
    get lastModifiedSetting() {
      let lastModifiedSetting;
      this.planSettings?.forEach(setting => {
        const lastModified = (0, _moment.default)(setting.lastModifiedDate);
        if (!lastModifiedSetting || lastModified.valueOf() > (0, _moment.default)(lastModifiedSetting.lastModifiedDate).valueOf()) {
          lastModifiedSetting = setting;
        }
      });
      return lastModifiedSetting;
    }
    get planSettingsMap() {
      return (0, _arrayUtils.buildHash)(this.planSettings?.toArray(), 'assetIdentifier');
    }
    get styleForStatus() {
      return `plan-status-${this.status?.toLowerCase()}`;
    }
    get totalExpectedBudget() {
      return Number((this.budgetEntries?.reduce((total, budgetEntry) => {
        total += budgetEntry.totalBudget;
        return total;
      }, 0)).toFixed(2));
    }
    get expenditureTypeBudgets() {
      return this.budgetEntries?.reduce((hash, budgetEntry) => {
        hash[`${budgetEntry.expenditureType}`] = budgetEntry.totalBudget;
        return hash;
      }, {}) || {};
    }
    get budgetAdjustments() {
      return this.budgetEntries?.filter(budgetEntry => budgetEntry.reason);
    }
    get statusCode() {
      return this.status[0];
    }
    init() {
      super.init();
      if (this.get('isNew')) {
        const date = (0, _moment.default)().utc().format('YYYY-MM-DD HH:mm:ss');
        this.set('createdDate', date);
        this.set('lastModifiedDate', date);
      }
    }

    /**
     * The endpoint refrenced in this task is slated to be deprecated and replaced with 'findAllByDisplayName'.
     * Unfortunately, there is no current way to indicate which grouping should be used when multiple groupings have
     * the same display name.  Until this issue can be resolved, we should avoid referencing the plan's grouping when
     * possible.
     */
    *getRegionTask() {
      return yield this.troveFetch.resolve(this.store.queryRecord('grouping', {
        endpoint: 'findFirstByDisplayName',
        params: {
          displayName: this.regionName
        }
      }), {
        errorMessage: 'Failed to get region info'
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "troveFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "createdBy", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "createdDate", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isHidden", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "lastModifiedDate", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "lidarYear", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "name", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "publishedDate", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "regionName", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "settingsVersion", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "year", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "publishedVersion", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "publishedPlanId", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "planSettings", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "budgetEntries", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "reports", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "region", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "isArchived", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "isDraft", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "isPublished", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "isSimulation", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "isVisible", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "lastModifiedSetting", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "lastModifiedSetting"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "planSettingsMap", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "planSettingsMap"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "styleForStatus", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "styleForStatus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "totalExpectedBudget", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "totalExpectedBudget"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "expenditureTypeBudgets", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "expenditureTypeBudgets"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "budgetAdjustments", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "budgetAdjustments"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getRegionTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getRegionTask"), _class.prototype), _class); // DO NOT DELETE: this is how TypeScript knows how to look up your models.
});