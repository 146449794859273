define("@trovedata/sunstone-ui-commons/components/paper-range-slider/component", ["exports", "@ember/service", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/runloop", "@ember/template", "@trovedata/sunstone-ui-commons/components/paper-range-slider/template", "ember-paper/mixins/focusable-mixin", "@trovedata/sunstone-ui-commons/mixins/trove-color-mixin", "ember-paper/utils/clamp", "@ember-decorators/component"], function (_exports, _service, _computed, _component, _object, _runloop, _template, _template2, _focusableMixin, _troveColorMixin, _clamp, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /* global Hammer */
  let PaperRangeSlider = _exports.default = (_dec = (0, _component2.layout)(_template2.default), _dec2 = (0, _component2.tagName)('md-slider'), _dec3 = (0, _component2.classNames)('md-default-theme', 'md-range'), _dec4 = (0, _component2.attribute)('md-discrete'), _dec5 = (0, _component2.className)('md-active'), _dec6 = (0, _component2.className)('md-dragging'), _dec7 = (0, _computed.not)('disabled'), _dec8 = (0, _object.computed)('percentMax', 'percent'), _dec9 = (0, _object.computed)('percent'), _dec10 = (0, _object.computed)('percentMax'), _dec11 = (0, _object.computed)('minValue', 'min', 'max'), _dec12 = (0, _object.computed)('maxValue', 'min', 'max'), _dec(_class = _dec2(_class = _dec3(_class = (_class2 = class PaperRangeSlider extends _component.default.extend(_focusableMixin.default, _troveColorMixin.default) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "constants", _descriptor, this);
      _initializerDefineProperty(this, "min", _descriptor2, this);
      _initializerDefineProperty(this, "max", _descriptor3, this);
      _initializerDefineProperty(this, "step", _descriptor4, this);
      _initializerDefineProperty(this, "tabindex", _descriptor5, this);
      _initializerDefineProperty(this, "valueDiffMin", _descriptor6, this);
      _initializerDefineProperty(this, "discrete", _descriptor7, this);
      _initializerDefineProperty(this, "active", _descriptor8, this);
      _initializerDefineProperty(this, "dragging", _descriptor9, this);
      _initializerDefineProperty(this, "enabled", _descriptor10, this);
    }
    get activeTrackStyle() {
      let percent = this.get('percent') || 0;
      let percentMax = this.get('percentMax') || 0;
      return (0, _template.htmlSafe)(`
      left: ${percent * 100}%;
      width: ${(percentMax - percent) * 100}%;
    `);
    }
    get thumbContainerStyleMin() {
      let percent = this.get('percent') || 0;
      return (0, _template.htmlSafe)(`left: ${percent * 100}%`);
    }
    get thumbContainerStyleMax() {
      let percent = this.get('percentMax') || 0;
      return (0, _template.htmlSafe)(`left: ${percent * 100}%`);
    }
    get percent() {
      let min = parseFloat(this.get('min'));
      let max = parseFloat(this.get('max'));
      return (0, _clamp.default)((this.get('minValue') - min) / (max - min), 0, 1);
    }
    get percentMax() {
      let min = parseFloat(this.get('min'));
      let max = parseFloat(this.get('max'));
      return (0, _clamp.default)((this.get('maxValue') - min) / (max - min), 0, 1);
    }
    didInsertElement() {
      super.didInsertElement();
      if (!this.get('disabled')) {
        this._setupHammer();
      }
    }
    didUpdateAttrs() {
      super.didUpdateAttrs();
      if (!this.get('disabled') && !this._hammer) {
        // if it is enabled and we didn't init hammer yet
        this._setupHammer();
      } else if (this.get('disabled') && this._hammer) {
        // if it is disabled and we did init hammer already
        this._teardownHammer();
      }
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      if (this._hammer) {
        this._teardownHammer();
      }
    }
    _setupHammer() {
      // Enable dragging the slider
      // @ts-ignore
      let containerManager = new Hammer.Manager(this.element);
      // @ts-ignore
      let pan = new Hammer.Pan({
        direction: Hammer.DIRECTION_HORIZONTAL,
        threshold: 10
      });
      containerManager.add(pan);
      containerManager.on('panstart', _runloop.run.bind(this, this.dragStart)).on('panmove', _runloop.run.bind(this, this.drag)).on('panend', _runloop.run.bind(this, this.dragEnd));
      // @ts-ignore
      this._hammer = containerManager;
    }
    _teardownHammer() {
      this._hammer.destroy();
      delete this._hammer;
    }
    positionToPercent(x) {
      let {
        left,
        width
      } = this.sliderDimensions();
      return Math.max(0, Math.min(1, (x - left) / width));
    }
    percentToValue(x) {
      let min = parseFloat(this.get('min'));
      let max = parseFloat(this.get('max'));
      return min + x * (max - min);
    }
    minMaxValidator(value, inputMin, inputMax) {
      let min = parseFloat(inputMin);
      let max = parseFloat(inputMax);
      return Math.max(min, Math.min(max, value));
    }
    stepValidator(value) {
      const step = Number(this.get('step'));
      if (step < 1) {
        return Number(value.toFixed(1));
      } else {
        return Math.round(value / step) * step;
      }
    }
    sliderDimensions() {
      return this.element.querySelector('.md-track-container').getBoundingClientRect();
    }
    setValueFromEvent(value, dragMethod) {
      const exactVal = this.percentToValue(this.positionToPercent(value));
      const closestVal = this.minMaxValidator(this.stepValidator(exactVal), dragMethod === 'Max' ? Number(this.get('minValue')) + this.get('valueDiffMin') : this.get('min'), dragMethod === 'Min' ? Number(this.get('maxValue')) - this.get('valueDiffMin') : this.get('max'));
      this.sendAction(`onChange${dragMethod}`, closestVal);
    }
    dragStart(event) {
      if (this.get('disabled')) {
        return;
      }
      this.set('active', true);
      this.set('dragging', true);
      this.element.focus();
      let dragMethod;
      let position = event.center.x;
      let compareVal = this.minMaxValidator(this.stepValidator(this.percentToValue(this.positionToPercent(position))), this.get('min'), this.get('max'));
      if (Math.abs(compareVal - this.get('minValue')) < Math.abs(compareVal - this.get('maxValue'))) {
        dragMethod = 'Min';
      } else {
        dragMethod = 'Max';
      }
      this.set('dragMethod', dragMethod);
      if (dragMethod) {
        this.setValueFromEvent(position, dragMethod);
      }
    }
    drag(event) {
      if (this.get('disabled') || !this.get('dragging')) {
        return;
      }
      let dragMethod = this.get('dragMethod');
      if (dragMethod) {
        this.setValueFromEvent(event.center.x, dragMethod);
      }
    }
    dragEnd() {
      if (this.get('disabled')) {
        return;
      }
      this.setProperties({
        active: false,
        dragging: false,
        dragMethod: null
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "constants", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "min", [_component2.attribute], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "max", [_component2.attribute], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 100;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "step", [_component2.attribute], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "tabindex", [_component2.attribute], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "valueDiffMin", [_component2.attribute], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "discrete", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "active", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "dragging", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "enabled", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "activeTrackStyle", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "activeTrackStyle"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "thumbContainerStyleMin", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "thumbContainerStyleMin"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "thumbContainerStyleMax", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "thumbContainerStyleMax"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "percent", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "percent"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "percentMax", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "percentMax"), _class2.prototype), _class2)) || _class) || _class) || _class);
});