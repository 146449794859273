define("@trovedata/sunstone-ui-commons/components/parameter-input/component", ["exports", "@ember/component", "@ember/object/computed", "@glimmer/component", "@ember/object", "@ember/array", "@ember/utils", "@ember/template-factory"], function (_exports, _component, _computed, _component2, _object, _array, _utils, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (eq this.inputType "checkbox")}}
    <PaperCheckbox
      @value={{this._paramValue}}
      @label={{this._label}}
      @onChange={{fn @onChange}}
      ...attributes
    />
  {{else}}
    {{#if this.temporalComponent}}
      {{component this.temporalComponent value=this.value onChange=(fn @onChange)}}
    {{else}}
      {{#if this.isArray}}
        <PaperSelectMultiple
          @options={{readonly @parameter.options}}
          @selected={{this._arrayParamValue}}
          @label={{this._label}}
          @closeOnSelect={{false}}
          @onChange={{fn @onChange}}
          @extra={{hash
            disableSelectAll=true
          }}
          ...attributes
          as |column select|>
          <PaperCheckbox
            class="flex layout-row layout-margin-none"
            @value={{contains column select.selected}}
            @onChange={{null}}
          >
            {{column}}
          </PaperCheckbox>
        </PaperSelectMultiple>
      {{else}}
        {{#if this.isSingleDropDown}}
          <PaperSelect
            @label={{this._label}}
            @options={{@parameter.options}}
            @renderInPlace={{true}} @selected={{this.value}}
            @onChange={{fn @onChange}}
            ...attributes
            as |option|
          >
            {{option}}
          </PaperSelect>
        {{else}}
          <PaperInput
            @value={{this.value}}
            @label={{this._label}}
            @onChange={{fn @onChange}}
            ...attributes
          />
        {{/if}}
      {{/if}}
    {{/if}}
  {{/if}}
  */
  {
    "id": "hZXtQKQX",
    "block": "[[[41,[28,[37,1],[[30,0,[\"inputType\"]],\"checkbox\"],null],[[[1,\"  \"],[8,[39,2],[[17,1]],[[\"@value\",\"@label\",\"@onChange\"],[[30,0,[\"_paramValue\"]],[30,0,[\"_label\"]],[28,[37,3],[[30,2]],null]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"temporalComponent\"]],[[[1,\"    \"],[46,[30,0,[\"temporalComponent\"]],null,[[\"value\",\"onChange\"],[[30,0,[\"value\"]],[28,[37,3],[[30,2]],null]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isArray\"]],[[[1,\"      \"],[8,[39,5],[[17,1]],[[\"@options\",\"@selected\",\"@label\",\"@closeOnSelect\",\"@onChange\",\"@extra\"],[[28,[37,6],[[30,3,[\"options\"]]],null],[30,0,[\"_arrayParamValue\"]],[30,0,[\"_label\"]],false,[28,[37,3],[[30,2]],null],[28,[37,7],null,[[\"disableSelectAll\"],[true]]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],[[24,0,\"flex layout-row layout-margin-none\"]],[[\"@value\",\"@onChange\"],[[28,[37,8],[[30,4],[30,5,[\"selected\"]]],null],null]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,4]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[4,5]]]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isSingleDropDown\"]],[[[1,\"        \"],[8,[39,9],[[17,1]],[[\"@label\",\"@options\",\"@renderInPlace\",\"@selected\",\"@onChange\"],[[30,0,[\"_label\"]],[30,3,[\"options\"]],true,[30,0,[\"value\"]],[28,[37,3],[[30,2]],null]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,6]],[1,\"\\n        \"]],[6]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,10],[[17,1]],[[\"@value\",\"@label\",\"@onChange\"],[[30,0,[\"value\"]],[30,0,[\"_label\"]],[28,[37,3],[[30,2]],null]]],null],[1,\"\\n\"]],[]]]],[]]]],[]]]],[]]]],[\"&attrs\",\"@onChange\",\"@parameter\",\"column\",\"select\",\"option\"],false,[\"if\",\"eq\",\"paper-checkbox\",\"fn\",\"component\",\"paper-select-multiple\",\"readonly\",\"hash\",\"contains\",\"paper-select\",\"paper-input\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/parameter-input/component.hbs",
    "isStrictMode": false
  });
  let ParameterInput = _exports.default = (_dec = (0, _computed.oneWay)('args.parameter.value'), _dec2 = (0, _computed.oneWay)('args.parameter.dataType'), _dec3 = (0, _computed.oneWay)('args.parameter.name'), _dec4 = (0, _object.computed)('label', 'args.label'), _dec5 = (0, _object.computed)('value', 'inputType'), _dec6 = (0, _object.computed)('value', 'isArray'), _dec7 = (0, _object.computed)('args.dataType', 'dataType'), _dec8 = (0, _object.computed)('lcDataType', 'parameter.hasOptions'), _dec9 = (0, _object.computed)('lcDataType', 'parameter.hasOptions'), _dec10 = (0, _object.computed)('lcDataType'), _dec11 = (0, _object.computed)('lcDataType'), _class = class ParameterInput extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "value", _descriptor, this);
      _initializerDefineProperty(this, "dataType", _descriptor2, this);
      _initializerDefineProperty(this, "label", _descriptor3, this);
    }
    get _label() {
      return this.args.label || this.label;
    }
    get _paramValue() {
      if ((0, _utils.typeOf)(this.value) === 'string' && this.inputType === 'checkbox') {
        (0, _object.set)(this.args.parameter, 'value', JSON.parse(this.args.parameter.value));
        return JSON.parse(this.value);
      } else {
        return this.value;
      }
    }
    get _arrayParamValue() {
      if ((0, _utils.typeOf)(this.value) === 'string') {
        if ((0, _utils.isEmpty)(this.value)) {
          (0, _object.set)(this.args.parameter, 'value', (0, _array.A)());
          return this.value;
        } else {
          (0, _object.set)(this.args.parameter, 'value', this.args.parameter.value.split(','));
          return this.value;
        }
      } else {
        return this.value;
      }
    }
    get lcDataType() {
      return String(this.args.dataType || this.dataType).toLowerCase();
    }
    get isArray() {
      return this.lcDataType === 'array' && this.args.parameter.hasOptions;
    }
    get isSingleDropDown() {
      return this.lcDataType === 'string' && this.args.parameter.hasOptions;
    }
    get temporalComponent() {
      const dataTypeTemporalComponentMap = {
        date: 'paper-date-input',
        time: 'paper-time-input',
        timestamp: 'paper-datetime-input'
      };
      let dataType = this.lcDataType;
      let temporalComponent;
      if (dataTypeTemporalComponentMap.hasOwnProperty(dataType)) {
        temporalComponent = dataTypeTemporalComponentMap[dataType];
      }
      return temporalComponent;
    }
    get inputType() {
      const dataTypeInputMap = {
        boolean: 'checkbox'
      };
      let dataType = this.lcDataType;
      let inputType = 'input';
      if (dataTypeInputMap.hasOwnProperty(dataType)) {
        inputType = dataTypeInputMap[dataType];
      }
      return inputType;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dataType", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "label", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "_label", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "_label"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_paramValue", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "_paramValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_arrayParamValue", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "_arrayParamValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "lcDataType", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "lcDataType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isArray", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "isArray"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isSingleDropDown", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "isSingleDropDown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "temporalComponent", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "temporalComponent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "inputType", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "inputType"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ParameterInput);
});