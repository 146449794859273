define("@trovedata/trove-scenario-planner/scenario-planner/route", ["exports", "ember-concurrency-decorators", "@ember/routing/route", "@ember/service"], function (_exports, _emberConcurrencyDecorators, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ScenarioPlannerRoute = _exports.default = (_class = class ScenarioPlannerRoute extends _route.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "budget", _descriptor, this);
      _initializerDefineProperty(this, "config", _descriptor2, this);
      _initializerDefineProperty(this, "customization", _descriptor3, this);
      _initializerDefineProperty(this, "featureFlag", _descriptor4, this);
      _initializerDefineProperty(this, "troveFetch", _descriptor5, this);
      _defineProperty(this, "appName", void 0);
      _defineProperty(this, "breadCrumb", {
        title: 'Asset Planning & Budgeting'
      });
      _defineProperty(this, "planGroupingYears", void 0);
      _defineProperty(this, "year", void 0);
    }
    beforeModel(transition) {
      const {
        targetName,
        to
      } = transition;
      const {
        queryParams
      } = to;

      // All non-existent routes
      if (targetName === 'scenario-planner.not-found') {
        this.replaceWith('/', {
          queryParams: {
            ...queryParams,
            year: new Date().getFullYear()
          }
        });
        return;
      }
      if (queryParams.year === undefined) {
        transition.abort();
        this.replaceWith(targetName, {
          queryParams: {
            ...queryParams,
            year: new Date().getFullYear()
          }
        });
        return;
      }
      this.year = queryParams.year;
      this.budget.budgetYear = queryParams.year;
    }
    async model() {
      // Retrieve config before UI resolves
      // @ts-ignore
      await this.getConfiguration.perform();
      // @ts-ignore
      await this.getAvailablePlanYears.perform();
      // @ts-ignore
      const types = await this.store.findAll('grouping-type');
      // @ts-ignore
      return types.filterBy('visible').sortBy('order');
    }
    setupController(controller, model, transition) {
      super.setupController(controller, model, transition);
      controller.appName = this.appName;
      controller.allPlanYearGroupingVersions = this.planGroupingYears;
      if (this.year) controller.year = this.year;
      const defaultType = model.findBy('defaultView');
      controller.selectedGroupingTypeIndex = model.indexOf(defaultType);
      // @ts-ignore
      controller.getAllServiceAreas.perform();
      // @ts-ignore
      controller.getAllLineStats.perform();
      // @ts-ignore
      this.customization.loadConfigurations.perform();
    }
    setAppName(plannerConfig) {
      const key = 'com.esource.scenario-planner-ui.name';
      const name = plannerConfig ? plannerConfig.source[key] : 'Transmission Vegetation';
      document.title = name;
      this.appName = name;
    }
    setFeatureFlags(plannerConfig) {
      if (!plannerConfig) return;
      this.featureFlag.budgetEnabled = plannerConfig.source['com.esource.scenario-planner-ui.budget-services.enabled'];
      this.featureFlag.createPlanEnabled = plannerConfig.source['com.esource.scenario-planner-ui.plan-actions.create.enabled'];
      this.featureFlag.editPlanEnabled = plannerConfig.source['com.esource.scenario-planner-ui.plan-actions.edit.enabled'];
      this.featureFlag.customPlannerEnabled = plannerConfig.source['com.esource.scenario-planner-ui.plan-actions.edit.custom'];
      this.featureFlag.whatIfEnabled = plannerConfig.source['com.esource.scenario-planner-ui.plan-actions.what-if.enabled'];
      this.featureFlag.publishPlanEnabled = plannerConfig.source['com.esource.scenario-planner-ui.plan-actions.publish.enabled'];
      this.featureFlag.defaultDistTab = plannerConfig.source['com.esource.scenario-planner-ui.default-dist-tab'];
      this.featureFlag.showProjectedStats = plannerConfig.source['com.esource.scenario-planner-ui.projected-stats.enabled'];
      this.featureFlag.showGroupingDashboardMap = plannerConfig.source['com.esource.scenario-planner-ui.grouping-dashboard.map-enabled'];
      this.featureFlag.showAssetDetailThreatData = plannerConfig.source['com.esource.scenario-planner-ui.plan-dashboard.asset-detail-components.show-threat-data'];
      this.featureFlag.defaultGroupingMap = plannerConfig.source['com.esource.scenario-planner-ui.grouping-dashboard.map-default'];
      this.featureFlag.useCustomizedStatComponents = plannerConfig.source['com.esource.scenario-planner-ui.customized-stat-components.enabled'];
      this.featureFlag.useSharedAssets = plannerConfig.source['com.esource.scenario-planner-ui.shared-assets.enabled'];
    }
    setConfigurations(plannerConfig) {
      if (!plannerConfig) return;
      const key1 = 'com.esource.scenario-planner-ui.plan-dashboard.left-panel-components';
      const key2 = 'com.esource.scenario-planner-ui.grouping-dashboard.grouping-card-components';
      const key3 = 'com.esource.scenario-planner-ui.modify-dashboard.right-panel-components';
      const key4 = 'com.esource.scenario-planner-ui.plan-dashboard.show-summary-card';
      const key5 = 'com.esource.scenario-planner-ui.plan-dashboard.is-map-expanded';
      const key6 = 'com.esource.scenario-planner-ui.plan-dashboard.show';
      this.customization.leftPanelComponents = plannerConfig.source[key1].split(',');
      this.customization.groupingCardComponents = plannerConfig.source[key2].split(',');
      this.customization.rightPanelComponents = plannerConfig.source[key3].split(',');
      this.customization.showSummaryCard = plannerConfig.source[key4];
      this.customization.isMapExpanded = plannerConfig.source[key5];
      this.customization.show = plannerConfig.source[key6];
    }
    *getConfiguration() {
      const {
        config,
        troveFetch
      } = this;
      const host = config.get('trove.apiGatewayBaseUrl');
      const url = `/spring-cloud-config-server/scenario-planner-ui/${config.get('activeProfiles')}`;
      const promise = troveFetch.request(url, {
        host,
        namespace: null
      });
      const configHash = yield troveFetch.resolve(promise);
      const plannerConfig = configHash?.propertySources?.find(({
        name
      }) => {
        return name.includes('scenario-planner-ui');
      });
      this.setAppName(plannerConfig);
      this.setFeatureFlags(plannerConfig);
      this.setConfigurations(plannerConfig);
    }
    *getAvailablePlanYears() {
      // @ts-ignore
      const {
        store,
        troveFetch
      } = this;
      const promise = store.findAll('plan-year-grouping-geo-data-version');
      const errorMessage = 'Failed to retrieve available plan years';
      const planGroupingYears = (yield troveFetch.resolvePromiseWithToast(promise, {
        errorMessage
      }))?.toArray();
      if (planGroupingYears?.length > 0 && !planGroupingYears.map(p => '' + p.planYear).includes(this.year)) {
        this.year = '' + planGroupingYears[planGroupingYears.length - 1].planYear;
      }
      this.planGroupingYears = planGroupingYears;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "budget", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "config", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "customization", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "featureFlag", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "troveFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getConfiguration", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getConfiguration"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getAvailablePlanYears", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getAvailablePlanYears"), _class.prototype), _class);
});