define("@trovedata/ember-keycloak-auth/components/keycloak-session-link", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service"], function (_exports, _component, _templateFactory, _component2, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.keycloakSession.ready}}
    {{#if this.keycloakSession.authenticated}}
      <a href="#" {{on "click" this.logout}}>
        <i class="fa fa-sign-out"></i> Sign out
      </a>
    {{else}}
      <a href="#" {{on "click" this.login}}>
        <i class="fa fa-sign-in"></i> Sign in
      </a>
    {{/if}}
  {{else}}
    No session
  {{/if}}
  
  */
  {
    "id": "Crxl5Gp+",
    "block": "[[[41,[30,0,[\"keycloakSession\",\"ready\"]],[[[41,[30,0,[\"keycloakSession\",\"authenticated\"]],[[[1,\"    \"],[11,3],[24,6,\"#\"],[4,[38,1],[\"click\",[30,0,[\"logout\"]]],null],[12],[1,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-sign-out\"],[12],[13],[1,\" Sign out\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[11,3],[24,6,\"#\"],[4,[38,1],[\"click\",[30,0,[\"login\"]]],null],[12],[1,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-sign-in\"],[12],[13],[1,\" Sign in\\n    \"],[13],[1,\"\\n\"]],[]]]],[]],[[[1,\"  No session\\n\"]],[]]]],[],false,[\"if\",\"on\"]]",
    "moduleName": "@trovedata/ember-keycloak-auth/components/keycloak-session-link.hbs",
    "isStrictMode": false
  });
  /**
   * @class KeycloakSessionLink
   * @public
   */
  let KeycloakSessionLink = _exports.default = (_class = class KeycloakSessionLink extends _component2.default {
    constructor(...args) {
      super(...args);
      /**
       * An injected keycloak session.
       *
       * @property keycloakSession
       * @type {KeycloakAdapterService}
       */
      _initializerDefineProperty(this, "keycloakSession", _descriptor, this);
    }
    login() {
      this.keycloakSession.login().then(result => {
        console.debug(result);
      });
    }
    logout() {
      this.keycloakSession.logout().then(result => {
        console.debug(result);
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "keycloakSession", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "login", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "login"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "logout", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "logout"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, KeycloakSessionLink);
});