define("@trovedata/trove-scenario-planner/models/plan-budget-adjustment", ["exports", "@ember/object", "@ember/service", "@ember/utils"], function (_exports, _object, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _PlanBudgetAdjustment;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let PlanBudgetAdjustment = _exports.default = (_dec = (0, _object.computed)('adjustmentType'), _dec2 = (0, _object.computed)('totalBudget'), _dec3 = (0, _object.computed)('adjustmentType', 'type', 'adjustmentAmount'), _dec4 = (0, _object.computed)('totalBudget', 'initialBudget'), _dec5 = (0, _object.computed)('adjustmentAmount'), _dec6 = (0, _object.computed)('modificationAmount', 'reason'), _class = (_PlanBudgetAdjustment = class PlanBudgetAdjustment extends _object.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "expenditureType", void 0);
      _defineProperty(this, "expenditureTypeDisplayName", void 0);
      _defineProperty(this, "adjustmentType", PlanBudgetAdjustment.REDUCTION);
      _defineProperty(this, "type", PlanBudgetAdjustment.FLAT);
      _defineProperty(this, "adjustmentAmount", 0);
      _defineProperty(this, "initialBudget", 0);
      _defineProperty(this, "reason", void 0);
      _initializerDefineProperty(this, "intl", _descriptor, this);
    }
    get adjustmentModifier() {
      return this.adjustmentType === PlanBudgetAdjustment.REDUCTION ? -1 : 1;
    }
    get totalBudgetLabel() {
      return `$${this.intl.formatNumber(this.totalBudget.toFixed(0))}`;
    }
    get totalBudget() {
      if (this.adjustmentType && this.type && this.adjustmentAmount) {
        if (this.type === PlanBudgetAdjustment.PERCENTAGE) {
          const percentageModifier = this.adjustmentModifier * (Number(this.adjustmentAmount) / 100);
          const result = this.initialBudget + this.initialBudget * percentageModifier;
          return Math.round(result);
        }
        if (this.type === PlanBudgetAdjustment.FLAT) {
          const result = this.initialBudget + this.adjustmentModifier * Number(this.adjustmentAmount);
          return Math.round(result);
        }
      }
      return Math.round(this.initialBudget);
    }
    get modificationAmount() {
      return (this.totalBudget - this.initialBudget).toFixed(2);
    }
    get adjustmentAmountIsSet() {
      return (0, _utils.isPresent)(this.adjustmentAmount) && this.adjustmentAmount > 0;
    }
    get reasonIsSet() {
      return !!this.reason;
    }
  }, _defineProperty(_PlanBudgetAdjustment, "REDUCTION", 'Reduction'), _defineProperty(_PlanBudgetAdjustment, "ADDITION", 'Addition'), _defineProperty(_PlanBudgetAdjustment, "PERCENTAGE", 'Percentage'), _defineProperty(_PlanBudgetAdjustment, "FLAT", 'Flat Amount'), _PlanBudgetAdjustment), _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "adjustmentModifier", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "adjustmentModifier"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "totalBudgetLabel", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "totalBudgetLabel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "totalBudget", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "totalBudget"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "modificationAmount", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "modificationAmount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "adjustmentAmountIsSet", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "adjustmentAmountIsSet"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reasonIsSet", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "reasonIsSet"), _class.prototype), _class);
});