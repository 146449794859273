define("@trovedata/sunstone-ui-commons/models/fuse-session", ["exports", "@trovedata/sunstone-ui-commons/models/model-session", "ember-data", "@ember/object", "@ember/service", "@ember/array", "ember-concurrency-decorators"], function (_exports, _modelSession, _emberData, _object, _service, _array, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const {
    belongsTo
  } = _emberData.default;
  let FuseSession = _exports.default = (_dec = (0, _object.computed)('datasetStats.[]'), _dec2 = (0, _object.computed)('datasetStats.[]'), _dec3 = belongsTo('fuse-job', {
    async: true
  }), _dec4 = (0, _object.computed)('currentExecutionUnit.name'), _dec5 = (0, _object.computed)('currentExecutionUnits.value'), _dec6 = (0, _object.computed)('currentExecutionUnits.value', 'isAllFinished'), _class = class FuseSession extends _modelSession.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "sunstoneAjax", _descriptor, this);
      _defineProperty(this, "datasetStats", void 0);
      _defineProperty(this, "currentExecutionUnits", void 0);
      _defineProperty(this, "executionUnitMap", {
        'datanormalization': {
          step: 1,
          icon: 'organization-flowchart-3',
          title: 'Customer Dataset Preparation'
        },
        'fusion': {
          step: 2,
          icon: 'synthesis',
          title: 'Fusion'
        },
        'portfolio': {
          step: 3,
          icon: 'user-group-information',
          title: 'Portfolio'
        },
        'statistic-collection': {
          step: 4,
          icon: 'user-graph',
          title: 'Stats Collection'
        }
      });
      _initializerDefineProperty(this, "modelInstance", _descriptor2, this);
    }
    *getSessionUnitsTask() {
      let currentExecutionUnits = yield this.get('sunstoneAjax').request(`api/modelSession/${this.get('id')}/executionUnitModelSessionDetail`, {
        namespace: 'trove-attribute-fusion'
      });
      return (0, _array.A)(currentExecutionUnits).map(unitData => {
        return {
          name: unitData.executionUnit.name,
          stats: unitData.stats
        };
      });
    }
    get faultyStat() {
      return (0, _array.A)(this.datasetStats).findBy('name', 'Faulty');
    }
    get totalStat() {
      return (0, _array.A)(this.datasetStats).findBy('name', 'Total');
    }
    get isExiting() {
      let currentExecutionUnit = this.get('currentExecutionUnit');
      let isExiting = false;
      if (currentExecutionUnit) {
        isExiting = `${currentExecutionUnit.name}`.split('exitpath').length === 2 || (0, _object.get)(currentExecutionUnit, 'stats.status') === 'STOPPED' ? true : false;
      }
      return isExiting;
    }
    get isAllFinished() {
      let isAllFinished = true;
      let currentExecutionUnits = this.currentExecutionUnits ? this.currentExecutionUnits.value : null;
      if (currentExecutionUnits) {
        currentExecutionUnits.forEach(unit => {
          if (unit.stats.status === 'RUNNING' || unit.stats.status === 'FAILED' || unit.stats.status === 'STOPPED' || !unit.stats.status) {
            isAllFinished = false;
          }
        });
      }
      return isAllFinished;
    }
    get currentExecutionUnit() {
      let currentExecutionUnit;
      let currentExecutionUnits = this.currentExecutionUnits ? this.currentExecutionUnits.value : null;
      let isAllFinished = this.get('isAllFinished');
      if (currentExecutionUnits) {
        currentExecutionUnit = currentExecutionUnits.find(unit => {
          return unit.stats.status === 'RUNNING' || unit.stats.status === 'STOPPED' || unit.stats.status === 'FAILED';
        });
        if (currentExecutionUnit) {
          let executionUnitMapUnit = this.executionUnitMap[currentExecutionUnit.name.split('_')[0]];
          (0, _object.set)(currentExecutionUnit, 'step', (0, _object.get)(executionUnitMapUnit, 'step'));
        }
        if (isAllFinished) {
          currentExecutionUnit = {
            step: 9999
          };
        }
      }
      return currentExecutionUnit;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "sunstoneAjax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getSessionUnitsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getSessionUnitsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "faultyStat", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "faultyStat"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "totalStat", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "totalStat"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modelInstance", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isExiting", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "isExiting"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isAllFinished", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "isAllFinished"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "currentExecutionUnit", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "currentExecutionUnit"), _class.prototype), _class);
});