define("@trovedata/sunstone-ui-commons/helpers/datetime", ["exports", "@ember/component/helper", "@ember/object", "@ember/service", "moment", "@ember-decorators/object"], function (_exports, _helper, _object, _service, _moment, _object2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let DatetimeHelper = _exports.default = (_dec = (0, _object2.observes)('sunstoneEnv.timezone', 'intl.locale'), _class = class DatetimeHelper extends _helper.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "sunstoneEnv", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
    }
    compute([date], {
      format,
      type,
      disableTimezone
    }) {
      if (!date) {
        return undefined;
      }
      let timezone = (0, _object.get)(this.sunstoneEnv, 'timezone');
      date = (0, _moment.default)(date);
      if (!type) {
        // babel does not allow 'default' in the destructured arguments, for some reason

        format = format || 'default';
        format = `datetime_${format}`;
        const options = {
          format
        };
        if (!disableTimezone) {
          options.timeZone = timezone;
        }
        return this.intl.formatDate(date.toISOString(), options);
      } else if (type === 'moment') {
        //@ts-ignore
        return (disableTimezone ? date : date.tz(timezone)).format(format ? format : 'MMM Do, YYYY, hh:mm A');
      }
    }
    settingsChanged() {
      this.recompute();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "sunstoneEnv", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "settingsChanged", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "settingsChanged"), _class.prototype), _class);
});